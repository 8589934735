import { faExclamation, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import PropTypes from "prop-types";
import React, { useEffect, useState, Fragment } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import * as yup from "yup";
import ContainerFluid from "components/containers/container-fluid";
import EventAutocomplte from "components/inputeventautocomplete";
import SideModal from "components/side-modal";
import SuperRadioButton from "components/super-radiobutton";
import { useLanguageSettings } from "hooks/language-settings";
import { cpfMask, numberMask, phoneMask } from "mask";
import Api from "services/api";
import { destinyKeys, finalityKeys, isWhitelabel } from "services/constants";
import { IpService } from "services/ip";
import New_api from "services/new_api";
import { ValidationsHelper } from "services/validations";
import { ReactComponent as Store } from "../../../assets/icons/store.svg";
import "./styles.css";
/* import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as FileArrowUp } from "../../../assets/icons/fileArrowUp.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/DownloadSimple.svg";
import { ReactComponent as TrashCan } from "../../../assets/icons/trashCan.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/X.svg"; */
import { parsedNumber } from "utils";
import { Trans, useTranslation } from "react-i18next";

//const documentJs = document;

const transferFormSchema = (isAdmin, available, isWhitelabel, translations) => {
  const {
    method,
    name,
    observation,
    amount,
    justificationDocumentLink,
    pixType,
    bankType,
    document,
    bank,
    agency,
    account,
    digit,
    origin,
    category,
    finality,
    destiny,
    pix,
  } = translations;

  return yup.object({
    method: yup.string().default("PIX").oneOf(["PIX", "TED"]).required(method),
    name: yup.string().trim().required(name),
    observation: yup.string().trim().max(1024, observation),
    amount: isAdmin
      ? yup
          .string()
          .required(amount.required)
          .test("min", amount.min, (val) => parsedNumber(val) > 0)
      : yup
          .string()
          .required(amount.required)
          .test("min", amount.min, (val) => parsedNumber(val) > 0)
          .test(
            "max",
            amount.max,
            (val) =>
              parseFloat(parsedNumber(val)) <=
              parseFloat(available === 0 ? 0 : available / 100)
          ),
    justificationDocumentLink:
      !isAdmin && !isWhitelabel
        ? yup
            .string()
            .when("amount", (value, field) =>
              parsedNumber(value) > available
                ? field.required(justificationDocumentLink)
                : field.notRequired()
            )
        : yup.string().notRequired(),
    type_account: yup
      .string()
      .when("method", (method, field) =>
        method === "PIX"
          ? field.required(pixType).oneOf(["email", "phone", "key", "document"])
          : field.required(bankType).oneOf(["savings", "checking"])
      ),
    document: yup
      .string()
      .trim()
      .required(document.required)
      .test(
        "documentTest",
        document.test,
        (value) =>
          value &&
          (ValidationsHelper.isCpfValid(value) ||
            ValidationsHelper.isCnpjValid(value))
      ),
    bank_id: yup
      .string()
      .when("method", (method, field) =>
        method === "PIX" ? field : field.required(bank)
      ),
    agency: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX" ? field : field.required(agency)
      ),
    account: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX" ? field : field.required(account)
      ),
    account_digit: yup
      .string()
      .trim()
      .when("method", (method, field) =>
        method === "PIX" ? field : field.required(digit)
      ),
    origin: isAdmin
      ? yup.string().required(origin).oneOf(["ticket", "a&b"])
      : yup.string().notRequired(),
    category: isAdmin
      ? yup.string().required(category).oneOf(["advance", "transfer"])
      : yup.string().notRequired(),
    finality: yup
      .string()
      .required(finality)
      .oneOf([
        "artist",
        "air",
        "food",
        "marketing",
        "bar",
        "structure",
        "operation",
        "online_anticipation",
        "pos_anticipation",
      ]),
    destiny: yup.string().oneOf(["organizer", "third party"]).required(destiny),
    pix_key: yup
      .string()
      .trim()
      .when("type_account", (type, field) => {
        if (type === "key") {
          return field.required(pix.key);
        } else if (type === "phone") {
          return field
            .required(pix.phone.required)
            .matches(
              /^\((\d{2})\) \D*(\d{5}|\d{4})\D*(\d{4})$/,
              pix.phone.test
            );
        } else if (type === "email") {
          return field.email(pix.email.required).required(pix.email.test);
        } else if (type === "document") {
          return field
            .required(pix.document.required)
            .test(
              "documentTest",
              pix.document.test,
              (value) =>
                value &&
                (ValidationsHelper.isCpfValid(value) ||
                  ValidationsHelper.isCnpjValid(value))
            );
        }
      }),
  });
};

const pixKeys = {
  document: "document",
  email: "email",
  phone: "phone",
  key: "key",
};

const originKeys = {
  ticket: "Ticket",
  "a&b": "A&B",
};

const categoryKeys = {
  advance: "advance",
  transfer: "transfer",
};

function ValueField(props) {
  const { register, error, onChange, label, placeholder } = props;

  return (
    <Form.Group as={Col} md="6">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        {...register("amount")}
        isInvalid={error}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}

function DocumentField({
  title,
  register,
  placeholder,
  error,
  onChange,
  value,
}) {
  return (
    <Form.Group as={Col} md="6">
      <Form.Label>{title}</Form.Label>
      <CpfCnpj
        {...register("document")}
        className={`form-control ${error ? "is-invalid" : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}

CreateTransferModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  eventId: PropTypes.number,
  maxValue: PropTypes.number,
  eventName: PropTypes.string,
  onError: PropTypes.func,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  formatCurrency: PropTypes.func,
  defaultEventOrgInfo: PropTypes.object,
};

export default function CreateTransferModal(props) {
  const { t } = useTranslation("transfers");

  //const fileInputRef = useRef(null);
  const { currencyInputFormatter } = useLanguageSettings();
  const {
    show,
    title,
    //maxValue,
    eventName,
    eventId,
    onClose,
    onError,
    onSuccess,
    currency,
    formatCurrency,
    defaultEventOrgInfo,
  } = props;

  const [banks, setBanks] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgInfoLoading, setOrgInfoLoading] = useState(false);
  const [customEvent, setCustomEvent] = useState("");
  const [customEventId, setCustomEventId] = useState(eventId);
  const [organizationInfo, setOrganizationInfo] = useState(null);
  const [availableToTransfer, setAvailableToTransfer] = useState(0);
  const [, setFile] = useState(null);
  const [, setJustificationFile] = useState(false);
  const [, setJustificationFileError] = useState(false);
  const [, setJustificationFileLoading] =
    useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const availableToTransferValue = () => {
    /* if (isWhitelabel) {
      return !user.is_admin ? maxValue / 100 : maxValue;
    }

    if (user.is_admin) {
      return maxValue
    } */

    if (defaultEventOrgInfo?.available > 0) {
      return defaultEventOrgInfo?.available;
    }

    if (organizationInfo?.available > 0) {
      return organizationInfo?.available;
    } else {
      return 0;
    }
  };

  const {
    reset,
    watch,
    register,
    setValue,
    resetField,
    clearErrors,
    handleSubmit,
    //setError,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(
      transferFormSchema(
        !!user.is_admin,
        Number(availableToTransferValue()).toFixed(2),
        isWhitelabel,
        {
          method: t("transfers:validation.method"),
          name: t("transfers:validation.name"),
          observation: t("transfers:validation.observation"),
          amount: {
            required: t("transfers:validation.requiredAmount"),
            min: t("transfers:validation.minAmount"),
            max: t("transfers:validation.maxAmount"),
          },
          justificationDocumentLink: t(
            "transfers:validation.justificationDocumentLink"
          ),
          pixType: t("transfers:validation.pixType"),
          bankType: t("transfers:validation.bankType"),
          document: {
            required: t("transfers:validation.document"),
            test: t("transfers:validation.documentTest"),
          },
          bank: t("transfers:validation.bank"),
          agency: t("transfers:validation.agency"),
          account: t("transfers:validation.acount"),
          digit: t("transfers:validation.acountDigit"),
          origin: t("transfers:validation.origin"),
          category: t("transfers:validation.category"),
          finality: t("transfers:validation.finality"),
          destiny: t("transfers:validation.destiny"),
          pix: {
            key: t("transfers:validation.pixKey"),
            phone: {
              required: t("transfers:validation.phone"),
              test: t("transfers:validation.phoneTest"),
            },
            email: {
              required: t("transfers:validation.email"),
              test: t("transfers:validation.emailTest"),
            },
            document: {
              required: t("transfers:validation.document"),
              test: t("transfers:validation.documentTest"),
            },
          },
        }
      )
    ),
  });

  //const amount = watch("amount");
  const method = watch("method");
  const pixKey = watch("type_account");
  const document = watch("document");

  const handleSelectEvent = ({ id }) => {
    setOrgInfoLoading(true);
    setCustomEventId(id);
    New_api.get(`transfers/organization-info/${id}`)
      .then(({ data }) => setOrganizationInfo(data))
      .catch((error) => console.error(error))
      .finally(() => setOrgInfoLoading(false));
  };

  const selectBank = (e) => setValue("bank_id", e.value);

  const handleClose = () => {
    if (!loading) {
      setJustificationFile(false);
      setFile(null);
      setJustificationFileError(false);
      setJustificationFileLoading(false);
      setOrganizationInfo(null);
      onClose();
    }
  };

  const handleFormData = async (form) => {
    setLoading(true);

    let ip;

    try {
      ip = await IpService.getMyIp();
    } catch {}

    let data = {
      ...form,
      amount: numberMask(form.amount),
      ip,
    };

    if (data.method === "PIX") {
      data = {
        ...data,
        agency: null,
        account: null,
        account_digit: null,
        bank_id: null,
      };
    }

    if (!user.is_admin) {
      data = {
        ...data,
        origin: null,
        category: "transfer",
      };
    }

    New_api.post(`/events/${eventId || customEventId}/transfers`, data)
      .then((result) => {
        onSuccess(result.data);
        onClose();
      })
      .catch((err) => onError(err))
      .finally(() => setLoading(false));
  };

  const handleDocumentValue = (event) => {
    let value = event.target.value;

    if (pixKey === "phone") {
      value = phoneMask(value);
    }

    if (pixKey === "document") {
      value = cpfMask(value);
    }

    setValue("pix_key", value);
  };

  const _fetchBanks = () => {
    Api.get("lista/bancos")
      .then((res) => {
        const data = res.data.map((item) => ({
          value: item.id,
          name: item.code + " - " + item.name,
        }));

        setBanks(data);
      })
      .catch(() => setBanks([]));
  };

  useEffect(() => {
    clearErrors();

    if (method) {
      setValue("type_account", method === "PIX" ? "document" : "checking");
      resetField("document");
    }
  }, [method, clearErrors, resetField, setValue]);

  useEffect(() => {
    reset({
      method: "PIX",
      type_account: "document",
      origin: "",
      category: "",
      finality: "",
      observation: "",
    });
    setChecked(false);
    setCustomEvent("");
    setCustomEventId(null);
    setAvailableToTransfer(0);

    if (show) {
      _fetchBanks();
      setAvailableToTransfer(availableToTransferValue());
    }
    // eslint-disable-next-line
  }, [show, reset]);

  //const handleDivJustificationClick = () => fileInputRef.current.click();

/*   const handleJustificationFile = (event) => {
    setJustificationFileLoading(true);
    const form_data = new FormData();
    const file_data = event.target.files[0];
    form_data.append("image", file_data);
    const maxFileSize = 1 * 1024 * 1024;
    setFile(file_data);

    if (file_data?.size > maxFileSize) {
      setJustificationFileLoading(false);
      justificationFileCanceled();
      setError("justificationDocumentLink", {
        type: "custom",
        message: t("transfers:justificationFileError"),
      });
      return;
    }

    Api.post("uploads?local=transferencias", form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(({ data: { url } }) => {
        setJustificationFile(true);
        if (errors?.justificationDocumentLink?.message) {
          clearErrors("justificationDocumentLink");
        }
        setValue("justificationDocumentLink", url);
      })
      .catch(() => {
        setFile(null);
        setJustificationFileError(true);
      })
      .finally(() => setJustificationFileLoading(false));
  }; */

/*   const justificationFileCanceled = () => {
    fileInputRef.current.value = "";
    setJustificationFile(false);
    setFile(null);
    setJustificationFileError(false);
    setValue("justificationDocumentLink", "");
  };
 */
/*   const handleDownloadFileClick = () => {
    try {
      if (file) {
        const href = URL.createObjectURL(file);
        const link = documentJs.createElement("a");
        link.href = href;
        link.setAttribute("download", file.name);
        documentJs.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(href);
        documentJs.body.removeChild(link);
      }
    } catch (error) {
      console.error(error);
    }
  }; */

  return (
    <SideModal show={show} title={title.toUpperCase()} onClose={handleClose}>
      <ContainerFluid>
        <Row>
          <Col>
            <Alert variant="danger" className="mt-3 w-100">
              <span className="icon-circle-sm">
                <FontAwesomeIcon icon={faExclamation} />
              </span>
              {eventName ? (
                <span>
                  <Trans i18nKey="transfers:form.warning" />
                </span>
              ) : (
                <span>
                  <Trans i18nKey="transfers:form.adminWarning" />
                </span>
              )}
            </Alert>
          </Col>
        </Row>
        <Form noValidate onSubmit={handleSubmit(handleFormData)}>
          {!eventId && (
            <Form.Row>
              <Form.Group as={Col} md="12" className="mb-0">
                <EventAutocomplte
                  value={customEvent}
                  onChange={setCustomEvent}
                  onSelect={handleSelectEvent}
                />
              </Form.Group>
            </Form.Row>
          )}

          {!!user.is_admin &&
            !isWhitelabel &&
            (orgInfoLoading || organizationInfo || defaultEventOrgInfo) && (
              <div className="organization-info-container d-flex align-items-center mt-3 mb-3">
                {orgInfoLoading ? (
                  <div className="d-flex w-100 flex-column align-items-center justify-content-center">
                    <span
                      className="spinner-border spinner-border-sm text-primary mx-1"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="organization-info-label mt-1">
                      {t("transfers:orgInfo.loading")}
                    </span>
                  </div>
                ) : (
                  <Fragment>
                    <div className="organization-info-icon">
                      <Store />
                    </div>
                    <Row>
                      <div className="organization-info-content d-flex flex-column col-md-8 col-sm-12 mb-2">
                        <span className="organization-info-label">
                          {t("transfers:orgInfo.organization")}
                        </span>
                        <span className="organization-info-data">
                          {organizationInfo?.organization?.name ||
                            defaultEventOrgInfo?.organization?.name}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-md-4 col mb-2">
                        <span className="organization-info-label">
                          {t("transfers:orgInfo.trustLevel")}
                        </span>
                        <span className="organization-info-data">
                          {organizationInfo?.organization?.trustLevel?.name ||
                            defaultEventOrgInfo?.organization?.trustLevel?.name}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-md-4 col">
                        <span className="organization-info-label">
                          {t("transfers:orgInfo.companyDoc")}
                        </span>
                        <span className="organization-info-data">
                          {organizationInfo?.organization?.document ||
                            defaultEventOrgInfo?.organization?.document ||
                            "-"}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-md-4 col organization-owner-style">
                        <span className="organization-info-label">
                          {t("transfers:orgInfo.responsible")}
                        </span>
                        <span className="organization-info-data">
                          {`${
                            organizationInfo?.user?.first_name ||
                            defaultEventOrgInfo?.user?.first_name
                          } ${
                            organizationInfo?.user?.last_name ||
                            defaultEventOrgInfo?.user?.last_name
                          }`}
                        </span>
                      </div>
                      <div className="d-flex flex-column col-md-4 col">
                        <span className="organization-info-label">
                          {t("transfers:orgInfo.userDoc")}
                        </span>
                        <span className="organization-info-data">
                          {organizationInfo?.organization?.owner_document ||
                            defaultEventOrgInfo?.organization?.owner_document}
                        </span>
                      </div>
                    </Row>
                  </Fragment>
                )}
              </div>
            )}
          {/* {customEventId && <hr />} */}
          {(eventId ||
            (customEventId && organizationInfo) ||
            defaultEventOrgInfo) && (
            <>
              {!isWhitelabel && (
                <Form.Row className="mb-3 ml-1 mt-3">
                  <div className="col-md-3 col-6 value-style-container">
                    <div className="d-flex flex-column">
                      <span className="value-style-label mb-2">
                        {t("transfers:form.available")}
                      </span>
                      <span className="value-style">
                        {formatCurrency(availableToTransfer, {
                          currency,
                          isCents: true,
                        })}
                      </span>
                    </div>
                  </div>
                </Form.Row>
              )}

              <Form.Row className="mb-4">
                <Form.Group as={Col} md="2" className="col-6 mb-0">
                  <SuperRadioButton
                    space={true}
                    requiredValue="PIX"
                    value={method}
                    label="PIX"
                    callback={() => setValue("method", "PIX")}
                  />
                </Form.Group>
                <Form.Group as={Col} md="2" className="col-6 mb-0">
                  <SuperRadioButton
                    space={true}
                    value={method}
                    requiredValue="TED"
                    label="TED"
                    callback={() => setValue("method", "TED")}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    {method === "PIX"
                      ? t("transfers:form.label.pixName")
                      : t("transfers:form.label.bankName")}
                  </Form.Label>
                  <Form.Control
                    {...register("name")}
                    placeholder={
                      method === "PIX"
                        ? t("transfers:form.placeholder.pixName")
                        : t("transfers:form.placeholder.bankName")
                    }
                    isInvalid={errors.name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {method === "PIX" && (
                  <ValueField
                    label={t("transfers:form.label.value")}
                    placeholder={t("transfers:form.placeholder.value")}
                    register={register}
                    error={errors.amount?.message}
                    onChange={(e) =>
                      setValue(
                        "amount",
                        currencyInputFormatter(e.target.value, currency)
                      )
                    }
                  />
                )}
                <DocumentField
                  title={t("transfers:form.label.document")}
                  placeholder={t("transfers:form.placeholder.document")}
                  register={register}
                  value={document}
                  error={errors.document?.message}
                  onChange={(e) => setValue("document", e.target.value)}
                />
              </Form.Row>

              {/* {Number(availableToTransfer / 100).toFixed(2) <
                parsedNumber(amount) &&
                !isWhitelabel && (
                  <div className="col-12 pl-0 my-2">
                    <span className="justification-file-label">
                      {t("transfers:form.label.justificationFile")}
                    </span>
                    <div className="col-12 mt-3 mb-3">
                      <div className="row d-flex justify-content-center">
                        <div className="col-2 col-md-1 p-0">
                          <div
                            style={{
                              backgroundColor: justificationFileError
                                ? "#FFE1D7"
                                : "#F5F5F7",
                            }}
                            className="icon-container"
                          >
                            {justificationFile || justificationFileError ? (
                              <FileArrowUp
                                fill={
                                  justificationFileError ? "red" : "#1A212B"
                                }
                              />
                            ) : justificationFileLoading ? (
                              <div
                                class="spinner-border text-primary"
                                style={{ width: "1.5rem", height: "1.5rem" }}
                                role="status"
                              ></div>
                            ) : (
                              <Upload />
                            )}
                          </div>
                        </div>
                        <div className="col-10 col-md-11 p-0">
                          <div className="col-12 pl-0">
                            <div className="row">
                              <div
                                className={
                                  justificationFile || justificationFileError
                                    ? "col-9 pl-0"
                                    : "col-12 pl-0"
                                }
                              >
                                <div className="d-flex flex-column">
                                  <div className="text-link-label col-12  p-0 m-0">
                                    {file && !justificationFileLoading
                                      ? file?.name
                                      : t(
                                          "transfers:form.justificationFile.name"
                                        )}
                                  </div>
                                  {justificationFileError ? (
                                    <div className="col-12 m-0 p-0">
                                      <span className="text-danger">
                                        <small style={{ color: "#dc3545" }}>
                                          {t(
                                            "transfers:form.justificationFile.error"
                                          )}
                                        </small>
                                      </span>
                                    </div>
                                  ) : justificationFile ? (
                                    <div className="col-12 col-md-6 p-0 m-0">
                                      {`${(file?.size / 1024).toFixed(2)} KB`}
                                    </div>
                                  ) : (
                                    <div
                                      name="addJustificationDocumentButton"
                                      onClick={handleDivJustificationClick}
                                      className="text-link-file col-12 col-md-6 p-0 m-0"
                                    >
                                      {t(
                                        "transfers:form.justificationFile.callToAction"
                                      )}
                                    </div>
                                  )}
                                </div>
                            </div>
                            {(justificationFile || justificationFileError) && (
                              <div className="col-3">
                                <div className="row h-100 align-items-center justify-content-end">
                                  {justificationFile ? (
                                    <Fragment>
                                      <div className="mr-2">
                                        <button
                                          type="button"
                                          onClick={handleDownloadFileClick}
                                          style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                          }}
                                        >
                                          <DownloadIcon fill="none" />
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          onClick={justificationFileCanceled}
                                          style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                          }}
                                        >
                                          <TrashCan stroke="#171719" />
                                        </button>
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <div>
                                      <button
                                        type="button"
                                        onClick={justificationFileCanceled}
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                        }}
                                      >
                                        <CloseIcon />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept=".pdf, .jpg, .jpeg, .png"
                    className="d-none"
                    onChange={handleJustificationFile}
                  />
                  {errors.justificationDocumentLink?.message && (
                    <div className="col-12">
                      <span className="text-danger">
                        <small style={{ color: "#dc3545" }}>
                          {errors.justificationDocumentLink?.message}
                        </small>
                      </span>
                    </div>
                  )}
                </div>
              )} */}

              {method === "PIX" ? (
                <Form.Row>
                  <Form.Group as={Col} md="6">
                    <Form.Label>{t("transfers:form.label.typeKey")}</Form.Label>
                    <Form.Control
                      as="select"
                      {...register("type_account")}
                      isInvalid={errors.type_account?.message}
                    >
                      <option value="" disabled>
                        {t("transfers:form.placeholder.typeKey")}
                      </option>
                      {Object.keys(pixKeys).map((key) => (
                        <option key={key} value={key}>
                          {t(`transfers:form.options.pixKeys.${pixKeys[key]}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.type_account?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>{t("transfers:form.label.PIXKey")}</Form.Label>
                    <Form.Control
                      {...register("pix_key")}
                      placeholder={`${t(
                        `transfers:form.placeholder.pixKeyPlaceholder.${pixKey}`
                      )}`}
                      isInvalid={errors.pix_key?.message}
                      onChange={handleDocumentValue}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pix_key?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              ) : (
                <>
                  <Form.Row>
                    <Form.Group as={Col} md="6">
                      <Form.Label>{t("transfers:form.label.bank")}</Form.Label>
                      <Select
                        options={banks.map((bank) => ({
                          value: bank.value,
                          label: bank.name,
                        }))}
                        onChange={selectBank}
                        placeholder={t("transfers:form.placeholder.bank")}
                        isInvalid={errors.bank_id?.message}
                      />
                      {errors.bank_id?.message && (
                        <ErrorFont className="text-danger mt-1">
                          {t("transfers:form.label.bankError")}
                        </ErrorFont>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                      <Form.Label>
                        {t("transfers:form.label.typeAccount")}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        {...register("type_account")}
                        isInvalid={errors.type_account?.message}
                      >
                        <option value="" disabled>
                          {t("transfers:form.placeholder.typeAccount")}
                        </option>
                        <option value="checking">
                          {t("transfers:form.label.checking")}
                        </option>
                        <option value="savings">
                          {t("transfers:form.label.savings")}
                        </option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.type_account?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="2">
                      <Form.Label>
                        {t("transfers:form.label.agency")}
                      </Form.Label>
                      <Form.Control
                        {...register("agency")}
                        maxLength="4"
                        placeholder={t("transfers:form.placeholder.account")}
                        isInvalid={errors.agency?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.agency?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" className="col-8">
                      <Form.Label>
                        {t("transfers:form.label.account")}
                      </Form.Label>
                      <Form.Control
                        {...register("account")}
                        placeholder={t("transfers:form.placeholder.account")}
                        type="number"
                        isInvalid={errors.account?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.account?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="1" className="col-4">
                      <Form.Label>{t("transfers:form.label.digit")}</Form.Label>
                      <Form.Control
                        {...register("account_digit")}
                        maxLength="1"
                        isInvalid={errors.account_digit?.message}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.account_digit?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <ValueField
                      label={t("transfers:form.label.value")}
                      placeholder={t("transfers:form.placeholder.value")}
                      register={register}
                      error={errors.amount?.message}
                      onChange={(e) =>
                        setValue(
                          "amount",
                          currencyInputFormatter(e.target.value, currency)
                        )
                      }
                    />
                  </Form.Row>
                </>
              )}
              {eventName ? null : (
                <>
                  <hr></hr>
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>{t("transfers:form.category")}</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("category")}
                        isInvalid={errors.category?.message}
                      >
                        <option value="" disabled>
                          {t("transfers:form.selectCategory")}
                        </option>
                        {Object.keys(categoryKeys).map((key) => (
                          <option key={key} value={key}>
                            {t(
                              `transfers:form.options.category.${categoryKeys[key]}`
                            )}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.category?.message}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                      <Form.Label>{t("transfers:form.origin")}</Form.Label>
                      <Form.Control
                        as="select"
                        {...register("origin")}
                        isInvalid={errors.origin?.message}
                      >
                        <option value="" disabled>
                          {t("transfers:form.selectOrigin")}
                        </option>
                        {Object.keys(originKeys).map((key) => (
                          <option key={key} value={key}>
                            {originKeys[key]}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.origin?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                </>
              )}

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>{t("transfers:form.finality")}</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("finality")}
                    isInvalid={errors.finality?.message}
                  >
                    <option value="" disabled>
                      {t("transfers:form.selectFinality")}
                    </option>
                    {Object.keys(finalityKeys).map((key) => (
                      <option key={key} value={key}>
                        {finalityKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.finality?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>{t("transfers:form.destination")}</Form.Label>
                  <Form.Control
                    as="select"
                    {...register("destiny")}
                    isInvalid={errors.destiny?.message}
                  >
                    <option value="">
                      {t("transfers:form.selectDestination")}
                    </option>
                    {Object.keys(destinyKeys).map((key) => (
                      <option key={key} value={key}>
                        {destinyKeys[key]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.destiny?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>
                    {t("transfers:form.label.observation")}
                  </Form.Label>
                  <Form.Control
                    style={{ resize: "none" }}
                    {...register("observation")}
                    name="observation"
                    autoComplete="nope"
                    type="textarea"
                    as="textarea"
                    rows={3}
                    placeholder={t("transfers:form.placeholder.observation")}
                    isInvalid={errors.observation?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.observation?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <TermsContainer
                name="termsCheckbox"
                onClick={() => setChecked(!checked)}
              >
                <Row noGutters>
                  <Col xs="2" md="1" className="d-flex justify-content-center">
                    <TermsCheckbox accepted={checked}>
                      <TermsCheckmark show={checked}>
                        <TermsCheckmarkStem></TermsCheckmarkStem>
                        <TermsCheckmarkKick></TermsCheckmarkKick>
                      </TermsCheckmark>
                    </TermsCheckbox>
                  </Col>
                  <Col md="10">
                    <TermsTitle>
                      {t("transfers:terms.textPart1")}{" "}
                      <TermsLink target="_blank" to="/termos-de-uso">
                        {t("transfers:terms.textPart2")}
                      </TermsLink>
                    </TermsTitle>
                    <TermsSubtitle>
                      {t("transfers:terms.textPart3")}
                    </TermsSubtitle>
                  </Col>
                </Row>
              </TermsContainer>

              <Row className="justify-content-end mt-3 mb-3">
                <Col md="5">
                  <Button
                    id="createTransfer"
                    variant="secondary"
                    type="submit"
                    disabled={!checked || loading}
                  >
                    {loading ? (
                      t("transfers:sending")
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                        {" " + title}
                      </>
                    )}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </ContainerFluid>
    </SideModal>
  );
}

//------- Styled Components ------//
const TermsContainer = styled.div`
  border: 2px solid #e6f0f6;
  border-radius: 8px;
  padding: 16px 0;
  background: #f1f9f9;
`;

const TermsTitle = styled.p`
  font-size: 13px;
  color: #4f6c7c;
  margin: 0 0 5px;
  font-weight: 400;
`;

const TermsSubtitle = styled.p`
  font-weight: 300;
  color: #8fa5b2;
  font-size: 11px;
  margin: 0;
`;

const TermsCheckmark = styled.span`
  display: ${(props) => (props.show ? "inline-block" : "none")};
  width: 38px;
  height: 9px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
`;

const TermsCheckmarkStem = styled.div`
  position: absolute;
  width: 3px;
  height: 12px;
  background-color: #ffffff;
  left: 9px;
  top: 3px;
`;

const TermsCheckmarkKick = styled.div`
  position: absolute;
  width: 6px;
  height: 3px;
  background-color: #ffffff;
  left: 4px;
  top: 12px;
`;

const TermsCheckbox = styled.div`
  width: 19px;
  height: 19px;
  border: 2px solid rgb(79 108 124 / 59%);
  border-radius: 3px;
  margin: 5px 0;
  cursor: pointer;
  background-color: ${(props) => (props.accepted ? "#00a7e5" : "none")};
  border-color: ${(props) =>
    props.accepted ? "#00a7e5" : "rgb(79 108 124 / 59%)"};
`;

const TermsLink = styled(Link)`
  text-decoration: underline;
  color: #2075f4;
`;

const ErrorFont = styled.span`
  font-size: 13px;
`;
