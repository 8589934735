import React, { useState } from "react";
import Card from "components/card";
import ToggleSwitch from "components/toggleSwitch";
import marketplace_ticket_api from "services/marketplace_ticket";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

const CardContent = ({ title, text, children, enabled }) => {
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: "1rem" }}
    >
      <div className="card-content-info d-flex flex-column">
        <span className="card-content-title font-weight-bold">{title}</span>
        {text && <span className="card-content-text">{text}</span>}
      </div>

      <div className={`card-content-child ${enabled ? "enabled" : ""}`}>
        {children}
      </div>
      <ReactTooltip place="top" effect="solid" className="text-center" />
    </div>
  );
};

const TransfersConfiguration = ({ eventTransferConfiguration }) => {
  const { allowCourtesyTransfer, allowPassportTransfer, allowTicketTransfer } =
    eventTransferConfiguration;
  const [enableTransfers, setEnableTransfers] = useState(allowTicketTransfer);
  const [enableCourtesiesTransfers, setEnableCourtesiesTransfers] = useState(
    allowCourtesyTransfer
  );
  const [enablePassportTransfers, setEnablePassportTransfers] = useState(
    allowPassportTransfer
  );

  const { t } = useTranslation("tickets");

  const updateEventTransferConfiguration = async (
    allowTicketTransfer,
    allowCourtesyTransfer,
    allowPassportTransfer
  ) => {
    let apiUrl = `api/v1/event-transfer-settings/update/${eventTransferConfiguration.id}`;
    try {
      const { id, ...rest } = eventTransferConfiguration;
      const data = {
        ...rest,
        allowTicketTransfer,
        allowCourtesyTransfer,
        allowPassportTransfer,
      };

      marketplace_ticket_api.put(apiUrl, data).then((res) => {
        setEnableTransfers(res.data.allowTicketTransfer);
        setEnableCourtesiesTransfers(res.data.allowCourtesyTransfer);
        setEnablePassportTransfers(res.data.allowPassportTransfer);
      });
    } catch (error) {
      console.error("Failed to fetch ticket transfers:", error);
    }
  };

  const handleToggleTransfer = () => {
    if (enableTransfers) {
      updateEventTransferConfiguration(false, false, false);
    } else {
      updateEventTransferConfiguration(
        !enableTransfers,
        enableCourtesiesTransfers,
        enablePassportTransfers
      );
    }
  };

  const handleToggleCourtesyTransfer = () => {
    if (enableTransfers)
      updateEventTransferConfiguration(
        enableTransfers,
        !enableCourtesiesTransfers,
        enablePassportTransfers
      );
  };

  const handleTogglePassportTransfer = () => {
    if (enableTransfers)
      updateEventTransferConfiguration(
        enableTransfers,
        enableCourtesiesTransfers,
        !enablePassportTransfers
      );
  };

  return (
    <div className="configurations-container">
      <Card>
        <CardContent
          title={t("nominalTransfers.configuration.title")}
          text={t("nominalTransfers.configuration.text")}
          enabled={enableTransfers}
        >
          <div className="d-flex switch-container">
            {enableTransfers
              ? t("nominalTransfers.configuration.enabled")
              : t("nominalTransfers.configuration.disabled")}
            <ToggleSwitch
              checked={enableTransfers}
              onChange={handleToggleTransfer}
              height={24}
              width={48}
            />
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent
          title={t("nominalTransfers.configuration.courtesyTitle")}
          text={t("nominalTransfers.configuration.courtesyText")}
          enabled={enableTransfers && enableCourtesiesTransfers}
        >
          <div
            className="d-flex switch-container"
            data-tip={
              !enableTransfers
                ? t("nominalTransfers.configuration.tooltipSwitch")
                : ""
            }
          >
            <span className={!enableTransfers ? "disabled" : ""}>
              {enableTransfers && enableCourtesiesTransfers
                ? t("nominalTransfers.configuration.enabled")
                : t("nominalTransfers.configuration.disabled")}
            </span>
            <ToggleSwitch
              checked={enableTransfers && enableCourtesiesTransfers}
              onChange={handleToggleCourtesyTransfer}
              height={24}
              width={48}
              disabled={!enableTransfers}
            />
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardContent
          title={t("nominalTransfers.configuration.passportTitle")}
          text={t("nominalTransfers.configuration.passportText")}
          enabled={enableTransfers && enablePassportTransfers}
        >
          <div
            className="d-flex switch-container"
            data-tip={
              !enableTransfers
                ? t("nominalTransfers.configuration.tooltipSwitch")
                : ""
            }
          >
            <span className={!enableTransfers ? "disabled" : ""}>
              {enableTransfers && enablePassportTransfers
                ? t("nominalTransfers.configuration.enabled")
                : t("nominalTransfers.configuration.disabled")}
            </span>
            <ToggleSwitch
              checked={enableTransfers && enablePassportTransfers}
              onChange={handleTogglePassportTransfer}
              height={24}
              width={48}
              disabled={!enableTransfers}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TransfersConfiguration;
