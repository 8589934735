import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import './style.css'

function ImageInstructionsModal({ show, handleClose }) {
    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton />
            <Modal.Body>
                <Modal.Title>{t('components:imageInstructionsModal.title')}</Modal.Title>
                <ul>
                    <li>
                        <strong>{t('components:imageInstructionsModal.clearInfoTitle')}</strong> - {t('components:imageInstructionsModal.clearInfoDesc')}
                    </li>
                    <li>
                        <strong>{t('components:imageInstructionsModal.safeAreaTitle')}</strong> - {t('components:imageInstructionsModal.safeAreaDesc')}
                    </li>
                    <li>
                        <strong>{t('components:imageInstructionsModal.multiUseTitle')}</strong> - {t('components:imageInstructionsModal.multiUseDesc')}
                    </li>
                    <li>
                        <strong>{t('components:imageInstructionsModal.textAndImageTitle')}</strong> - {t('components:imageInstructionsModal.textAndImageDesc')}
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    {t('components:imageInstructionsModal.concludeButton')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ImageInstructionsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default ImageInstructionsModal;
