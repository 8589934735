/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faBorderAll,
  faClone,
  faEllipsisV,
  faFileExcel,
  faFilter,
  faGlobeAmericas,
  faInfoCircle,
  faLink,
  faListUl,
  faRemoveFormat,
  faSearch,
  faStream,
  faSyncAlt,
  faTicketAlt,
  faWineBottle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyData from "components/empty-data/index.js";
import EventsHeader from "components/headerevents";
import Loading from "components/loading/index.js";
import React, { Fragment, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import Modal from "react-modal";
import { NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastr";
import styled from "styled-components";
import Swal from "sweetalert2";

import CustomButtonSpinner from "components/buttonSpinner";
import EventStatus from "components/eventStatusBadge";
import ModalEvents from "components/modalFilterEvents";
import { PDV_MODULE, PERMISSIONAB, breakPoints } from "services/constants";
import { ImageHandlerService } from "services/image-handler.js";

import "rsuite/lib/styles/index.less";
import MapPinLine from "../../../assets/icons/MapPinLine.svg";
import MonitorPlay from "../../../assets/icons/MonitorPlay.svg";
import Api from "../../../services/api.js";
import NewApi from "../../../services/new-api.js";
import "./styles.css";
import ModalDestaq from "./modalDestaq/index.js";
import ModalHistory from "./modalHistory/index.js";
// eslint-disable-next-line import/no-cycle
import ModalProducer from "./modalProducer/index.js";
import ModalTypeEvent from "./modalTypeEvent/index.js";
import { useTranslation } from "react-i18next";

import "rsuite/lib/styles/index.less";
import { monthName } from "utils/months";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

const linkEmpresa = process.env.REACT_APP_LINK_EMPRESA;

let container;

// eslint-disable-next-line no-undef
const documentJS = document;

// eslint-disable-next-line no-undef
const user = JSON.parse(localStorage.getItem("user"));

// eslint-disable-next-line no-undef
const windowJS = window;

// Essa variavel existe para limitar o numero de eventos por pagina
const PAGE_LIMIT = 25;

function ChangeEventStatusModal({ show, event, onSuccess, onError, onClose }) {
  const [status, setStatus] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    if (!loading) {
      onClose();
    }
  };

  const handleSave = () => {
    setLoading(true);

    Api.put(`event/${event.id}/update-status`, { status })
      .then(() => {
        onSuccess(event, status);
      })
      .catch(() => onError())
      .finally(() => setLoading(false));
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  React.useEffect(() => {
    if (event) {
      setStatus(event.event_status);
    }
  }, [event]);

  if (!show) {
    return <></>;
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          background: "rgba(59, 59, 59, 0.5)",
        },
      }}
      ariaHideApp={false}
      contentLabel={t("events:eventList.eventStatusModal.title")}
      id="change-status-modal"
    >
      <button
        onClick={handleClose}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>

      <div className="change-status-modal-container">
        <div className="row">
          <h4 className="col-12 text-center">
            {t("events:eventList.eventStatusModal.title")}
          </h4>
        </div>
        <div className="row">
          <span className="col-12 text-center">{event.name}</span>
        </div>
        <div className="row mt-3">
          <select
            className="form-control select2-hidden-accessible"
            tabIndex="-1"
            aria-hidden="true"
            value={status}
            disabled={loading}
            onChange={(e) => handleChangeStatus(e)}
          >
            <option value="ativo">{t("global:statusList.active")}</option>
            <option value="offline">{t("global:statusList.offline")}</option>
            <option value="adiado">{t("global:statusList.postponed")}</option>
            <option value="encerrado">{t("global:statusList.closed")}</option>
            <option value="cancelado">{t("global:statusList.canceled")}</option>
          </select>
        </div>
        <div className="row mt-3">
          <button
            type="button"
            className="btn btn-primary col-12"
            onClick={handleSave}
            disabled={loading}
          >
            {loading
              ? t("events:eventList.processing")
              : t("events:eventList.update")}
          </button>
        </div>
      </div>
    </Modal>
  );
}

function Events() {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const search = new URLSearchParams(useLocation().search);
  const [startDatePage, setStartDatePage] = useState(null);
  const [endDatePage, setEndDatePage] = useState(null);

  const [isAdm, setIsAdm] = useState(false);
  const [mouseLeave, setMouseLeave] = useState(false);
  const [statusModal, setStatusModal] = useState("modal-hidden");
  const [statusModalProducer, setStatusModalProducer] =
    useState("modal-hidden");
  const [statusModalFilter, setStatusModalFilter] = useState("modal-hidden");
  const [statusModalHistory, setStatusModalHistory] = useState("modal-hidden");
  const [events, setEvents] = useState();
  const [eventId, setEventId] = useState(null);
  const [filter, setFilter] = useState("");
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState([]);
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState([]);
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState([]);
  const [porPagina, setPorPagina] = useState(PAGE_LIMIT);
  const [filterTypeEvents, setFilterTypeEvents] = useState("all");
  const [statusFilterEvent, setStatusFilterEvent] = useState("");
  const [totalEventTipoIngresso, settotalEventTipoIngresso] = useState(0);
  const [totalEventTipoAeB, settotalEventTipoAeB] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // VERIFICA PERMISSÃO PARA EXIBIR A&B (PDV)
  const [PDV, setPDV] = useState({ state: false });
  const [modalPublicIsOpen, setModalPublicIsOpen] = useState(false);
  const [organization_id, setOrganization_id] = useState(null);
  const [exhibitionType, setExhibitionType] = useState(2);
  const [duplicatingEvent, setDuplicatingEvent] = useState(false);

  // CHANGE STATUS MODAL STATES
  const [showChangeModal, setShowChangeModal] = React.useState(false);
  const [currentEvent, setCurrentEvent] = React.useState({});

  const updateFilter = (e) => {
    setFilter(e.target.value);
    loadEvents("initial", 1, e.target.value);
  };

  function fetchAdminPermissions() {
    if (PDV_MODULE === "1") {
      setPDV({ state: true });
    }
  }

  function downloadXlsx() {
    setIsLoading(true);
    const params = {
      organization_id: Boolean(search.get("org_id"))
        ? search.get("org_id")
        : organization_id,
      startDate: startDatePage,
      endDate: endDatePage,
      byName: "",
      typeEvents: Boolean(search.get("org_id")) ? "all" : filterTypeEvents,
      filterEventStatus: Boolean(search.get("org_id"))
        ? "all"
        : statusFilterEvent,
    };
    Api.post("generatorEventsXLSX/xlsx", params, {
      responseType: "arraybuffer",
    })
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${"evento"}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoading(false);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: t("global:confirmButtonText"),
        });
      });
  }

  function loadEvents(typePage, numberPage, filterByName) {
    setIsLoading(true);

    let params = {
      organization_id: Boolean(search.get("org_id"))
        ? search.get("org_id")
        : organization_id,
      startDate: startDatePage,
      endDate: endDatePage,
      byName: filterByName,
      page: numberPage,
      perPage: porPagina,
      typeEvents: Boolean(search.get("org_id")) ? "all" : filterTypeEvents,
      filterEventStatus: Boolean(search.get("org_id"))
        ? "all"
        : statusFilterEvent,
    };

    switch (typePage) {
      case "initial":
        params = {
          ...params,
          startDate: startDatePage,
          endDate: endDatePage,
          byName: filterByName,
          page: 1,
          perPage: porPagina,
        };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          params = {
            ...params,
            startDate: startDatePage,
            endDate: endDatePage,
            byName: filterByName,
            page: numberPage,
            perPage: porPagina,
          };
        } else {
          numberPage++;
          params = {
            ...params,
            startDate: startDatePage,
            endDate: endDatePage,
            byName: filterByName,
            page: numberPage,
            perPage: porPagina,
          };
        }

        break;

      case "left":
        numberPage--;
        params = {
          ...params,
          startDate: startDatePage,
          endDate: endDatePage,
          byName: filterByName,
          page: numberPage,
          perPage: porPagina,
        };

        if (numberPage <= 0) {
          params = {
            ...params,
            startDate: startDatePage,
            endDate: endDatePage,
            byName: filterByName,
            page: 1,
            perPage: porPagina,
          };
        }
        break;

      case "lastPage":
        params = {
          ...params,
          startDate: startDatePage,
          endDate: endDatePage,
          byName: filterByName,
          page: numberPage,
          perPage: porPagina,
        };

        break;
      default:
        params = {
          ...params,
          startDate: startDatePage,
          endDate: endDatePage,
          byName: filterByName,
          page: 1,
          perPage: porPagina,
        };
    }

    Api.post("admin/allevents", params)
      .then((result) => {
        setEvents(result.data.resultPagination.data);
        setIsLoading(false);

        setAtualizaPaginaAtual(result.data.resultPagination.page);
        setAtualizaUltimaPagina(result.data.resultPagination.lastPage);
        setAtualizaQuantPesquisa(result.data.resultPagination.total);

        if (filterTypeEvents === "all") {
          setTotalEvents(result.data.totalEvents);
          settotalEventTipoIngresso(result.data.totalEventTicket);
          settotalEventTipoAeB(result.data.totalEventAeB);
        } else if (filterTypeEvents === 1) {
          settotalEventTipoIngresso(result.data.totalEventTicket);
        } else if (filterTypeEvents === 0) {
          settotalEventTipoAeB(result.data.totalEventAeB);
        }

        if (documentJS.getElementById("loading")) {
          documentJS.getElementById("loading").classList.add("esconde");
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: t("global:confirmButtonText"),
        });
      });
  }

  useEffect(() => {
    if (user.is_admin === 1) {
      setIsAdm(true);
    }
    loadEvents("initial", 1, filter);
    fetchAdminPermissions();
    // eslint-disable-next-line
  }, [
    startDatePage,
    porPagina,
    filterTypeEvents,
    statusFilterEvent,
    organization_id,
  ]);

  function changeFilter(type) {
    setFilterTypeEvents(type);
  }

  function tirarDestaque(e, item) {
    e.preventDefault();
    Swal.fire({
      title: t("events:eventList.alerts.removeHighlight.title"),
      icon: "warning",
      text: t("events:eventList.alerts.removeHighlight.subtitle"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3f25a8",
      confirmButtonText: t("global:yes"),
      cancelButtonText: t("global:no"),
    }).then((result) => {
      if (result.value) {
        Api.get(`/tirar/destacar/evento/${item.id}`)
          .then(() => {
            container.success(
              t("events:eventList.alerts.removeHighlight.successResponse")
            );
          })
          .catch(() => {
            container.error(
              t("events:eventList.alerts.removeHighlight.errorResponse")
            );
          });
      }
    });
  }

  function openDropdown(event, i) {
    event.preventDefault();
    const dropdowns = documentJS.getElementsByClassName("dropdown-buttons");
    if (!dropdowns[i].classList.contains("show-dropdown")) {
      if (dropdowns.length > 0) {
        for (let index = 0; index < dropdowns.length; index++) {
          dropdowns[index].classList.remove("show-dropdown");
        }
        dropdowns[i].classList.add("show-dropdown");
      }
      dropdowns[i].style["z-index"] = "1";
    } else {
      dropdowns[i].classList.remove("show-dropdown");
    }
  }

  function tableView(grid) {
    const cardList = documentJS.getElementById("list-events");
    const btnActivate1 = documentJS.getElementById("btntableview-1");
    const btnActivate2 = documentJS.getElementById("btntableview-2");

    if (grid === 1 && !cardList.classList.contains("card-box")) {
      cardList.classList.add("card-box");
      cardList.classList.remove("card-list");
      btnActivate1.classList.add("active");
      btnActivate2.classList.remove("active");
    } else if (grid === 2 && !cardList.classList.contains("card-list")) {
      cardList.classList.add("card-list");
      cardList.classList.remove("card-box");
      btnActivate2.classList.add("active");
      btnActivate1.classList.remove("active");
    }
  }

  function openModal(event, id) {
    event.preventDefault();
    setEventId(id);
    setStatusModal("modal-show");
  }

  const closeModal = () => {
    setStatusModal("modal-hidden");
  };

  function openModalProducer(event, id) {
    event.preventDefault();
    setEventId(id);
    setStatusModalProducer("modal-show");
  }

  const closeModalProducer = () => {
    setStatusModalProducer("modal-hidden");
  };

  function openModalFilter(id) {
    setEventId(id);
    setStatusModalFilter("modal-show");
  }

  const closeModalFilter = () => {
    setEventId("");
    setStatusModalFilter("modal-hidden");
  };

  function openModalHistory(event, id) {
    event.preventDefault();
    setEventId(id);
    setStatusModalHistory("modal-show");
  }

  const closeModalHistory = () => {
    setEventId("");
    setStatusModalHistory("modal-hidden");
  };

  const copyLinkToClipboard = (text) => {
    const textArea = documentJS.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    // avoid scrolling to bottom
    documentJS.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    container.success(t("events:eventList.copyLinkSuccess"));

    documentJS.execCommand("copy");
    documentJS.body.removeChild(textArea);
  };

  const activeButtonPainelBar = (event, item) => {
    event.preventDefault();
    (async () => {
      await Swal.fire({
        text: t("events:eventList.alerts.selectOperationModel"),
        icon: "warning",
        input: "select",
        inputOptions: {
          ticket: t("events:eventList.alerts.inputOptions.ticket"),
          "cashless-pos": t(
            "events:eventList.alerts.inputOptions.cashless-pos"
          ),
          "cashless-pre": t(
            "events:eventList.alerts.inputOptions.cashless-pre"
          ),
        },
        inputPlaceholder: t(
          "events:eventList.alerts.selectOperationModelPlaceHolder"
        ),
        showCancelButton: true,
        cancelButtonText: t("events:eventList.alerts.cancel"),
        confirmButtonText: t("events:eventList.alerts.register"),
        inputValidator: (value) => {
          return new Promise((resolve) => {
            switch (value) {
              case "cashless-pos":
                resolve();
                activePainelBar(value, item.id);
                break;
              case "cashless-pre":
                resolve();
                activePainelBar(value, item.id);
                break;
              case "ticket":
                resolve();
                activePainelBar(value, item.id);
                break;
              default:
                resolve(t("events:eventList.alerts.mustSelectOperationModel"));
                break;
            }
          });
        },
      });
    })();
  };

  const activeButtonTicketPanel = (event, dataEvent) => {
    event.preventDefault();
    Swal.fire({
      text: t("events:eventList.alerts.ticketPanelAlerts.text"),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("events:eventList.alerts.cancel"),
      confirmButtonText: t("events:eventList.alerts.enable"),
    }).then((result) => {
      if (result.value) {
        history.push({
          pathname: `/TicketEventTransform/${dataEvent.id}`,
        });
      }
    });
  };

  const activePainelBar = (type, id) => {
    const params = { type, event_id: id };

    Api.post("update/updatetypepainelaeb", params)
      .then((res) => {
        container.success(t("global:successUpdate"));

        if (res) {
          windowJS.location = `/bar/dashboard?event_id=${id}`;
        }
      })
      .catch(() => {
        container.error(t("global:errorSaving"));
      });
  };

  function passRef(ref) {
    container = ref;
  }

  const filterEvent = (payload) => {
    setStartDatePage(payload.startDate);
    setEndDatePage(payload.endDate);
    setStatusFilterEvent(payload.statusFilter);
    setOrganization_id(payload.organization_id);
  };

  const openChangeStatusModal = (e, event) => {
    e.preventDefault();
    setMouseLeave(false);
    setCurrentEvent(event);
    setShowChangeModal(true);
  };

  const handleStatusChangeError = () => {
    Swal.fire({
      title: t("global:error"),
      icon: "warning",
      text: t("events:eventList.eventStatusModal.alerts.error"),
      confirmButtonText: t("global:confirmButtonText"),
    });
  };

  const handleStatusChangeSucceed = (event, status) => {
    setShowChangeModal(false);

    Swal.fire({
      title: t("global:success"),
      icon: "success",
      text: t("events:eventList.eventStatusModal.alerts.success"),
      confirmButtonText: t("global:confirmButtonText"),
    });

    events.find((item) => item.id === event.id).event_status = status;

    setEvents([...events]);
  };

  const createEvent = (status) => {
    Api.get("validToCreateEventOrEstablishment").then((res) => {
      const userIsAdmin = user.is_admin;

      if (res.data || userIsAdmin) {
        if (PERMISSIONAB) {
          setModalPublicIsOpen(status);
        } else {
          window.location.href = "/event/create";
        }
      } else {
        Swal.fire({
          text: t("events:eventList.alerts.createOrganization"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: t(
            "events:eventList.alerts.createOrganizationConfirm"
          ),
          cancelButtonText: t("events:eventList.alerts.cancel"),
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            window.location.href = "/organizacao";
          }
        });
      }
    });
  };

  const handleExhibition = (number) => {
    tableView(number);
    setExhibitionType(number);
  };

  const duplicateEvent = async (event, item) => {
    event.preventDefault();
    const { isConfirmed } = await Swal.fire({
      title: t("events:eventList.duplicateEventConfirmationText", {
        eventName: item.name,
      }),
      text: t("events:eventList.duplicateEventConfirmationSubText"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t(
        "events:eventList.duplicateEventConfirmationConfirmText"
      ),
      cancelButtonText: t(
        "events:eventList.duplicateEventConfirmationCancelText"
      ),
    });
    if (isConfirmed) {
      setDuplicatingEvent(true);

      try {
        const duplication = await NewApi.post(`/events/${item.id}/duplicate`);
        if (duplication.status === 201) {
          setDuplicatingEvent(false);

          Swal.fire({
            title: t("events:eventList.duplicateEventSuccessTitle"),
            text: t("events:eventList.duplicateEventSuccessText"),
            icon: "success",
            showCancelButton: false,
            confirmButtonText: t("global:confirmButtonText"),
          }).then((result) => {
            if (result.isConfirmed) {
              const { id } = duplication.data.message;
              history.push({
                pathname: `/tickets/new-event-edit/evento?event_id=${id}`,
              });
            }
          });
        } else {
          throw new Error(t("events:eventList.duplicateEventErrorText"));
        }
      } catch (error) {
        setDuplicatingEvent(false);

        Swal.fire({
          title: t("global:somethingWentWrong"),
          text: t("events:eventList.duplicateEventErrorTextTryAgain"),
          icon: "error",
          showCancelButton: false,
          confirmButtonText: t("global:confirmButtonText"),
        });
      }
    }
  };

  return (
    <Fragment>
      <Loading title={t("global:loadingText")} />

      {duplicatingEvent && (
        <Loading title={t("events:eventList.loadingTextPage")} />
      )}

      <ToastContainer ref={(ref) => passRef(ref)} className="toast-top-right" />
      <NewPageHeader
        className="bg-white"
        title={t("events:eventList.title")}
        subtitle={t("events:eventList.subtitle")}
      >
        <ButtonContained
          onClick={() => createEvent(true)}
          className="primary-contained new-primary-contained"
          content={t("events:eventList.headerBtnText")}
        />
      </NewPageHeader>
      {totalEventTipoAeB > 0 && (
        <EventsHeader
          filterFunction={changeFilter}
          position={filterTypeEvents}
          totalEvents={totalEvents}
          totalEventTipoAeB={totalEventTipoAeB}
          totalEventTipoIngresso={totalEventTipoIngresso}
        />
      )}
      <div className="content mt-2 container-fluid">
        <div className="row">
          <div id="event-filters" className="col-12">
            <SearchBarContainer style={{ padding: "15px" }}>
              <SearchBarElement style={{ width: "100%" }}>
                <div className="card-search d-flex">
                  <FontAwesomeIcon icon={faSearch} className="icon-search" />
                  <DebounceInput
                    minLength={2}
                    debounceTimeout={300}
                    className="input-custom"
                    onChange={updateFilter}
                    autoComplete="off"
                    placeholder={t("events:eventList.searchInputPlaceholder")}
                  />
                </div>
              </SearchBarElement>
              <SearchBarElement>
                <VisualizedButton
                  style={{
                    borderRadius: "5px",
                    border: "none",
                  }}
                  type="button"
                  id="btntableview-1"
                  className="btn-cards-event btn-white m-0"
                  onClick={() => {
                    handleExhibition(1);
                  }}
                >
                  <FontAwesomeIcon
                    style={{ paddingRight: "10px" }}
                    icon={faBorderAll}
                  />
                </VisualizedButton>
              </SearchBarElement>
              <SearchBarElement>
                <VisualizedButton
                  style={{
                    borderRadius: "5px",
                    border: "none",
                  }}
                  type="button"
                  id="btntableview-2"
                  className="btn-cards-event btn-white active m-0"
                  onClick={() => {
                    handleExhibition(2);
                  }}
                >
                  <FontAwesomeIcon
                    style={{ paddingRight: "10px" }}
                    icon={faListUl}
                  />
                </VisualizedButton>
              </SearchBarElement>
              <SearchBarElement style={{ width: "100%" }}>
                <CustomButtonSpinner
                  customStyle={"col-12 btn btn-lg btn-outline-primary-export"}
                  triggerOnClick={downloadXlsx}
                  isLoading={isLoading}
                  text={t("events:eventList.buttons.exportGeneralReport")}
                >
                  <FontAwesomeIcon icon={faFileExcel} />
                </CustomButtonSpinner>
              </SearchBarElement>

              <SearchBarElement style={{ width: "100%" }}>
                <div
                  type="button"
                  className="col-12 btn btn-lg btn-outline-primary-events"
                  onClick={() => openModalFilter()}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <FontAwesomeIcon icon={faFilter} />
                  {t("events:eventList.buttons.advancedFilters")}
                </div>
              </SearchBarElement>
            </SearchBarContainer>
          </div>
        </div>

        <div id="list-events" className="col-12 card-list">
          <div className="row">
            {events?.length > 0 ? (
              events.map((item, i) => (
                <div
                  key={i.toString()}
                  className="card-grid col-12 col-sm-6 col-xl-4 col-xxl-3"
                >
                  <NavLink
                    className="card-event shadow-sm"
                    to={`/tickets/dashboard?event_id=${item.id}`}
                  >
                    {/* MAIN INFOS */}
                    <div className="card-event-infos col-4">
                      <div className="card-date text-center">
                        <span className="date-day">
                          {new Date(item.start_date).getDate()}
                        </span>
                        <p className="date-month">
                          {monthName(new Date(item.start_date), {
                            locale: i18n.language,
                          }).toLocaleUpperCase()}
                        </p>
                      </div>

                      <div
                        className="event-img"
                        style={{
                          backgroundImage: `url(${ImageHandlerService.getImageFromUrl(
                            item.vertical_banner || item.thumb,
                            {
                              format: "webp",
                              quality: 50,
                              resize: {
                                width: 100,
                                fit: "contain",
                              },
                            },
                            "logo-pocket.jpg"
                          )})`,
                        }}
                      />

                      <div className="card-event-name">
                        <p className="event-name">{item.name}</p>
                        <p className="event-location">
                          {!!user.is_admin && (
                            <NavLink
                              to={`/organizacao?org_id=${item.organization_id}`}
                              className="event-organization"
                            >
                              {`${item.organization_name} - `}
                            </NavLink>
                          )}
                          {item.location}
                        </p>
                      </div>
                    </div>

                    {/* STATUS BAR */}
                    <div className="event-progress-bar col-3">
                      {/* Value */}
                      <div className="small mr-2">
                        {t("events:eventList.sold", {
                          count: item.ingressosVendidos,
                        })}
                        {`${item.ingressosVendidos}/${item.ingressosDisponiveis} |`}
                        <b
                          style={{
                            marginLeft: "5px",
                          }}
                        >
                          {item.ingressosDisponiveis === 0
                            ? 0
                            : (
                                (item.ingressosVendidos /
                                  item.ingressosDisponiveis) *
                                100
                              ).toFixed(2)}
                          %
                        </b>
                      </div>
                      <div className="progress progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              item.ingressosDisponiveis === 0
                                ? 0
                                : (
                                    (item.ingressosVendidos /
                                      item.ingressosDisponiveis) *
                                    100
                                  ).toFixed(2)
                            }%`,
                          }}
                          aria-label="0"
                          aria-valuenow="29"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>

                    <div
                      className={`row ${
                        exhibitionType === 2 ? "col-md-4" : ""
                      } col-12 `}
                    >
                      <div className="col-10 d-flex justify-content-start align-items-center">
                        <div style={{ maxHeight: "25px" }}>
                          {item.is_online ? (
                            <Fragment>
                              <img
                                src={MonitorPlay}
                                alt="MonitorPlay Icon"
                                className="mr-1"
                                height={25}
                                width={25}
                              />
                              <span className="type-event-text">
                                {t("events:eventList.onlineEvent")}
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <img
                                src={MapPinLine}
                                alt="MapPinLine Icon"
                                className="mr-1"
                              />
                              <span className="type-event-text">
                                {t("events:eventList.liveEvent")}
                              </span>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <div className="col-2 p-0 d-flex justify-content-end align-items-center">
                        <EventStatus status={item.event_status} />
                      </div>
                    </div>

                    <button
                      type="button"
                      className="dotsMobile"
                      value={i}
                      onClick={(event) => openDropdown(event, i)}
                      onMouseEnter={() => setMouseLeave(true)}
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>

                    {mouseLeave && (
                      <div
                        onMouseLeave={() => setMouseLeave(false)}
                        className="dropdown-buttons dropdown-area"
                      >
                        {/* BUTTONS */}
                        {isAdm &&
                          (item.destaque === "ACTIVE" ? (
                            <NavLink
                              to=""
                              onClick={(e) => tirarDestaque(e, item)}
                              className="dropdown-item"
                            >
                              <FontAwesomeIcon icon={faRemoveFormat} />
                              <span>
                                {t(
                                  "events:eventList.eventOptionsModal.removeHighlight"
                                )}
                              </span>
                            </NavLink>
                          ) : (
                            <button
                              type="button"
                              id="dropModal"
                              onClick={(e) => openModal(e, item.id)}
                              className="dropdown-item"
                            >
                              <FontAwesomeIcon icon={faGlobeAmericas} />
                              <span>
                                {t(
                                  "events:eventList.eventOptionsModal.highlightSite"
                                )}
                              </span>
                            </button>
                          ))}

                        <button
                          type="button"
                          id="dropModal"
                          onClick={(e) => openChangeStatusModal(e, item)}
                          className="dropdown-item"
                        >
                          <FontAwesomeIcon icon={faSyncAlt} />
                          <span>
                            {t(
                              "events:eventList.eventOptionsModal.changeStatus"
                            )}
                          </span>
                        </button>
                        {item.hasTickets === 1 ? (
                          <NavLink
                            to={`/tickets/dashboard?event_id=${item.id}`}
                            className="dropdown-item"
                          >
                            <FontAwesomeIcon icon={faTicketAlt} />
                            <span>
                              {t(
                                "events:eventList.eventOptionsModal.ticketPanel"
                              )}
                            </span>
                          </NavLink>
                        ) : (
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={(event) => {
                              activeButtonTicketPanel(event, item);
                            }}
                          >
                            <FontAwesomeIcon icon={faTicketAlt} />
                            <span>
                              {t(
                                "events:eventList.eventOptionsModal.ticketPanel"
                              )}
                            </span>
                          </button>
                        )}

                        {PDV.state &&
                          (item.type ? (
                            <NavLink
                              className="dropdown-item"
                              to={`/bar/dashboard?event_id=${item.id}`}
                            >
                              <FontAwesomeIcon icon={faWineBottle} />
                              <span>
                                {t(
                                  "events:eventList.eventOptionsModal.a&bPanel"
                                )}
                              </span>
                            </NavLink>
                          ) : (
                            PERMISSIONAB && (
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={(event) => {
                                  activeButtonPainelBar(event, item);
                                }}
                              >
                                <FontAwesomeIcon icon={faWineBottle} />
                                <span>
                                  {t(
                                    "events:eventList.eventOptionsModal.a&bPanel"
                                  )}
                                </span>
                              </button>
                            )
                          ))}

                        <button
                          type="button"
                          id="dropModal"
                          className="dropdown-item"
                          onClick={(event) => openModalProducer(event, item.id)}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          {t(
                            "events:eventList.eventOptionsModal.producerInformations"
                          )}
                        </button>
                        <button
                          type="button"
                          id="dropModal"
                          className="dropdown-item"
                          onClick={(event) => {
                            event.preventDefault();
                            copyLinkToClipboard(
                              `${linkEmpresa}/eventos/${item.slug}`
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faLink} />
                          {t("events:eventList.eventOptionsModal.eventLink")}
                        </button>
                        <button
                          type="button"
                          id="dropModal"
                          className="dropdown-item"
                          onClick={(event) => openModalHistory(event, item.id)}
                        >
                          <FontAwesomeIcon icon={faStream} />
                          {t(
                            "events:eventList.eventOptionsModal.statusHistory"
                          )}
                        </button>
                        <button
                          type="button"
                          id="dropModal"
                          className="dropdown-item"
                          onClick={(event) => duplicateEvent(event, item)}
                        >
                          <FontAwesomeIcon icon={faClone} />
                          {t(
                            "events:eventList.eventOptionsModal.duplicateEvent"
                          )}
                        </button>
                      </div>
                    )}
                  </NavLink>
                </div>
              ))
            ) : (
              <EmptyData title={t("events:eventList.noEventRegistered")} />
            )}
          </div>
        </div>
        <div className="row d-flex justify-content-between mb-3 datatable-footer">
          <div className="col-md-6 d-flex align-items-center">
            <span className="mr-2">
              {t("global:pagination.resultsPerPage")}
            </span>
            <div>
              <select
                name="SelecaoPorPagina"
                onChange={(e) => setPorPagina(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
            {porPagina * atualizaPaginaAtual >= atualizaQuantPesquisa ? (
              <span>
                {t("global:pagination.exhibiting", {
                  from: atualizaQuantPesquisa,
                  to: atualizaQuantPesquisa,
                })}
              </span>
            ) : (
              <span>
                {t("global:pagination.exhibiting", {
                  from: porPagina * atualizaPaginaAtual,
                  to: atualizaQuantPesquisa,
                })}
              </span>
            )}

            <button
              disabled={isLoading}
              type="button"
              onClick={() => loadEvents("initial", 1, filter)}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </button>
            <button
              disabled={isLoading}
              type="button"
              onClick={() => loadEvents("left", atualizaPaginaAtual, filter)}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <span>
              {t("global:pagination.page", {
                page: atualizaPaginaAtual,
                pages: atualizaUltimaPagina,
              })}
            </span>

            <button
              disabled={isLoading}
              type="button"
              onClick={() => loadEvents("right", atualizaPaginaAtual, filter)}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>

            <button
              disabled={isLoading}
              type="button"
              onClick={() =>
                loadEvents("lastPage", atualizaUltimaPagina, filter)
              }
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </button>
          </div>
        </div>
      </div>
      <ModalDestaq
        callback={closeModal}
        status={statusModal}
        event_id={eventId}
      />
      <ModalProducer
        callback={closeModalProducer}
        status={statusModalProducer}
        idEvent={eventId}
      />
      <ModalEvents
        id={eventId}
        callback={closeModalFilter}
        status={statusModalFilter}
        filter={filterEvent}
        dataInit={startDatePage}
        dataFim={endDatePage}
      />
      <ChangeEventStatusModal
        show={showChangeModal}
        event={currentEvent}
        onError={handleStatusChangeError}
        onClose={() => setShowChangeModal(false)}
        onSuccess={handleStatusChangeSucceed}
      />
      <ModalHistory
        callback={closeModalHistory}
        status={statusModalHistory}
        idEvent={eventId}
        events={events}
      />
      {modalPublicIsOpen && (
        <ModalTypeEvent
          toggleFunction={{ modalPublicIsOpen, setModalPublicIsOpen }}
          // togglePublic={isOpen}
        />
      )}
    </Fragment>
  );
}

export default Events;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakPoints.tablet} {
    flex-direction: row;
  }
`;

const SearchBarElement = styled.div`
  padding: 5px 0 5px 0;
  @media ${breakPoints.tablet} {
    padding: 0 5px 0 5px;
  }
`;

const VisualizedButton = styled.button`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  @media ${breakPoints.tablet} {
    max-width: 50px;
  }
`;
