import { GroupHeader } from "pages/common-area/events/event-create/event-create-steps/event-create-step-1/groupHeader";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as AddCircle } from "../../assets/icons/addCircle.svg";
import { ReactComponent as LandscapeWL } from "../../assets/icons/landscapeIconWL.svg";
import { ReactComponent as TrashBin } from "../../assets/icons/trashBin.svg";
import { ReactComponent as TrashBinWL } from "../../assets/icons/trashCan.svg";
import "./styles.css";

export function MyDropzone({
  title,
  description,
  callback,
  preview,
  deleteCallback,
  isWhitelabel,
}) {
  const [opacity, setOpacity] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => callback(reader.result);
      reader.readAsArrayBuffer(file);
    });
    setErrorMessage("");
    // eslint-disable-next-line 
  }, []);

  function sizeValidator(file) {
    if (!isWhitelabel && file.size > 716800) {
      const error = "A imagem deve ter tamanho máximo de 700 KB. Tente com outro arquivo.";
      setErrorMessage(error);
      return {
        code: "size-too-large",
        message: error,
      };
    }
    return null;
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: isWhitelabel
      ? { "image/jpeg": [], "image/jpg": [], "image/png": [] }
      : { "image/jpeg": [], "image/png": [], "image/webp": [] },
    maxFiles: 1,
    onDrop,
    validator: sizeValidator,
  });

  const handleDelete = () => {
    if (preview) {
      const deleteOrigin = isWhitelabel ? (title.text === "Banner" ? "banner" : "thumb") : title.text;
      deleteCallback(deleteOrigin);
    }
  };

  const renderPreviewImage = () => (
    preview && <img style={{ opacity }} className="img-preview" src={preview} alt="Component preview" />
  );

  const renderTrashIcon = () => (
    preview && (isWhitelabel ? (
      <TrashBinWL onClick={handleDelete} fill="red" />
    ) : (
      <TrashBin onClick={handleDelete} />
    ))
  );

  const renderContent = () => (
    <div
      className={`${isWhitelabel
        ? (title.text === "Banner" ? "banner" : "thumb")
        : title.text} ${preview ? "w-preview" : ""}`}
      onMouseEnter={() => setOpacity(0.1)}
    >
      <div className={`${isWhitelabel ? "col-auto pr-0 justify-content-center d-flex" : "d-flex flex-column align-items-center"}`}>
        {isWhitelabel ? <LandscapeWL /> : <AddCircle width={48} height={48} />}
      </div>
      <div className={`col-${isWhitelabel ? "10" : "auto"} ${isWhitelabel ? "text-left pr-0" : "text-center"}`}>
        <GroupHeader
          title={title.text}
          tclass={title.class}
          description={description.text}
          dclass={description.class}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (!isWhitelabel && fileRejections.length > 0) {
      const error = fileRejections[0].errors[0].message;
      setErrorMessage(error);
    }
    // eslint-disable-next-line 
  }, [fileRejections]);

  return (
    <div
      onMouseLeave={() => setOpacity(1)}
      onMouseEnter={() => setOpacity(0.1)}
      className={`dropzone ${!isWhitelabel && "flex-column"} ${preview ? "w-preview-group" : ""}`}
    >
      {errorMessage && <p style={{ color: 'red', fontSize: "12px" }}>{errorMessage}</p>}
      <div className={`trash ${preview ? "w-preview" : ""}`} onMouseEnter={() => setOpacity(0.1)}>
        {renderTrashIcon()}
      </div>
      <div
        className={`${isWhitelabel
          ? `${title.text === "Banner" ? "dropzone-banner-wl" : "dropzone-thumb"} img-group-wl`
          : "dropzone-banner img-group"} position-relative`}
        {...getRootProps()}>
        {renderPreviewImage()}
        <input {...getInputProps()} />
        {renderContent()}
      </div>
    </div>
  );
}
