import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Form, Container } from "react-bootstrap";
import Select from "react-select";
import EmptyData from "components/empty-data";
import Loading from "components/loading";
import Swal from "sweetalert2";
import New_api from "services/new_api";

import { ModalAssociateMap } from "./modalAssociateMap";
import { SeatsioEventManager } from "@seatsio/seatsio-react";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";

const SEATSIOKEY = process.env.REACT_APP_SEATSIO_KEY;

const managerViewFilters = [
  {
    value: "manageObjectStatuses",
    label: "Gerenciamento de reservas e assentos",
  },
  {
    value: "manageForSaleConfig",
    label: "Gerenciamento de assentos à venda ou não",
  },
  {
    value: "manageTableBooking",
    label: "Gerenciamento de mesas",
  },
  {
    value: "manageChannels",
    label: "Gerenciamento da lista de canais",
  },
  {
    value: "manageCategories",
    label: "Gerenciamento de categorias",
  },
  {
    value: "select",
    label: "Gerenciamento de licenças",
  },
  {
    value: "filterSections",
    label: "Gerenciamento de seções",
  },
  {
    value: "static",
    label: "Visualização do mapa completo",
  },
];

export default function EventSeatsMaps() {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const id = params.get("event_id");

  const [eventInfos, setEventInfos] = useState({
    name: "",
    eventDate: "",
    seats_integration_id: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [eventKey, setEventKey] = useState();
  const [selectedMode, setSelectedMode] = useState(managerViewFilters[0]);
  const [isMapAssociated, setIsMapAssociated] = useState();
  const [mapList, setMapList] = useState();
  const [isAssociateOpen, setIsAssociateOpen] = useState(false);

  useEffect(() => {
    Promise.all([
      New_api.get(`/events/${id}`, {
        params: { fields: ["name", "start_date", "seats_integration_id"] },
      }),
      New_api.get(`/charts`, {
        params: {
          event_id: id
        }
      }),
    ])
      .then(([eventResponse, chartsResponse]) => {
        setEventInfos({
          name: eventResponse.data.name,
          eventDate: eventResponse.data.start_date,
          seats_integration_id: eventResponse.data.seats_integration_id,
        });
        if (eventResponse.data.seats_integration_id) {
          setIsMapAssociated(true);
          setEventKey(eventResponse.data.seats_integration_id);
        } else {
          setIsMapAssociated(false);
        }
        setMapList(chartsResponse.data.data);
      })
      .catch(() =>
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        })
      )
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openModal() {
    setIsAssociateOpen(true);
  }

  function closeModal() {
    setIsAssociateOpen(false);
  }

  function handleEventKey(eventKey) {
    setEventKey(eventKey);
  }

  function handleAssociationStatus(status) {
    setIsMapAssociated(status);
  }

  function handleDisassociate() {
    Swal.fire({
      icon: "warning",
      title: "Atenção",
      text: "Você tem certeza que deseja remover a associação de lugares marcados do evento? Ao realizar tal ação, o evento não poderá mais vender lugares marcados.",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.isConfirmed) {
        New_api.delete(`/events/${id}/seats/disassociate`)
          .then(() => {
            handleAssociationStatus(false);
            Swal.fire({
              title: "Sucesso!",
              icon: "success",
              text: "Mapa desassociado com sucesso!",
              confirmButtonText: "Ok",
            });
          })
          .catch((error) =>
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: error.response.data.message,
              confirmButtonText: "Ok",
            })
          );
      }
    });
  }

  return (
    <>
      {isLoading && <Loading title="aguarde..." />}

      <ModalAssociateMap
        closeModal={closeModal}
        eventInfos={eventInfos}
        mapList={mapList}
        isAssociateOpen={isAssociateOpen}
        handleAssociationStatus={handleAssociationStatus}
        handleEventKey={handleEventKey}
        id={id}
      />
      <NewPageHeader
        title="Mapas de Assentos"
        subtitle="Criação e gerenciamento do mapa de lugares do evento"
        className="bg-white"
      >
        <ButtonContained
          className="primary-contained new-primary-contained"
          onClick={isMapAssociated ? handleDisassociate : openModal}
          content={isMapAssociated ? "Dessasociar Mapa" : "Associar mapa"}
        />
      </NewPageHeader>
      {isMapAssociated ? (
        <>
          <Card className="p-3">
            <Row>
              <Col sm={12}>
                <Form.Label>Modo de Visualização</Form.Label>
                <Select
                  defaultValue={selectedMode}
                  onChange={(mode) => setSelectedMode(mode)}
                  placeholder="Modo de Visualização"
                  options={managerViewFilters}
                />
              </Col>
            </Row>
          </Card>
          <Container fluid>
            <div style={{ height: "650px" }}>
              <SeatsioEventManager
                secretKey={SEATSIOKEY}
                event={eventKey}
                mode={selectedMode.value}
                region={process.env.REACT_APP_SEATSIO_REGION}
                language="pt"
              />
            </div>
          </Container>
        </>
      ) : (
        <EmptyData title="Não há um mapa associado a este evento" />
      )}
    </>
  );
}
