import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash/debounce";
import React, { Fragment, useEffect, useState } from "react";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";

import ButtonContained from "components/buttonContained";
import NewInput from "components/newInput";
import NewSelect from "components/newSelect";
import CreateTransferModal from "components/transfer-modals/create-transfer-modal";
import TransferDetailsModal from "components/transfer-modals/transfer-details-modal";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import { useLanguageSettings } from "hooks/language-settings";
import { destinyKeys, isWhitelabel, transferDestiny } from "services/constants";
import New_api from "services/new-api";
import api from "services/api";
import { formatCurrency } from "utils";

import { ReactComponent as Download2 } from "../../../assets/icons/download2.svg";
import { ReactComponent as Search } from "../../../assets/icons/Search.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning-yellow.svg";

import "./styles.css";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewPageHeader from "components/newPageHeader";
import { TransferCards } from "./transferCards";
import { dateFormatter } from "utils/date-formatter";
import { TableSkeleton } from "./tableSkeleton";

// eslint-disable-next-line
let container;

export default function FinanceiroTransferencias() {
  const user = JSON.parse(localStorage.getItem("user"));
  const urlParams = new URLSearchParams(window.location.search);
  const activateTransferLock = process.env.REACT_APP_TRANSFER_LOCK === "1";
  const initialPerPage = 25;
  const { currency: backofficeCurrency, symbol: backofficeSymbol } =
    useLanguageSettings();
  const { currency: eventCurrency, currencySymbol: eventSymbol } =
    useCurrencyEventContext();

  const [eventId, setEventId] = useState();
  const [transferencias, setTransferencias] = useState([]);
  // eslint-disable-next-line
  const event_id = urlParams.get("event_id");
  const {
    t,
    i18n: { language },
  } = useTranslation("transfers");
  const [costs, setCosts] = useState();
  const [summary, setSummary] = useState({
    available: 0,
    waiting: 0,
    paid: 0,
    analyse: 0,
    in_analysis: 0,
    pending: 0,
    blocked: 0,
    blockedPercentage: 0,
    netBalance: 0,
  });
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState();
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState();
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState();
  const [filteredByStatus, setFilteredByStatus] = useState([]);
  const [filteredByDestiny, setFilteredByDestiny] = useState();
  const [filteredByRecipientOrEvent, setFilteredByRecipientOrEvent] =
    useState();
  const [reload, setReload] = useState(false);
  const [perPage, setPerPage] = useState(initialPerPage);

  //New States
  const [eventName, setEventName] = useState();
  const [showCreate, setShowCreate] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [defaultEventOrgInfo, setDefaultEventOrgInfo] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [isEventContext, setEventContext] = useState(false);
  const [originalStatusList, setOriginalStatusList] = useState([]);
  const currency = isEventContext ? eventCurrency : backofficeCurrency;
  const symbol = isEventContext ? eventSymbol : backofficeSymbol;

  const statusBuilder = {
    SOLICITADO: {
      class: "requested",
      name: "SOLICITADO",
      displayName: `${t("transfers:badges.requested").toUpperCase()}`,
    },
    "EM ANÁLISE": {
      class: "review",
      name: "EM ANÁLISE",
      displayName: `${t("transfers:badges.review").toUpperCase()}`,
    },
    APROVADO: {
      class: "approved",
      name: "APROVADO",
      displayName: `${t("transfers:badges.approved").toUpperCase()}`,
    },
    PAGO: {
      class: "paid",
      name: "PAGO",
      displayName: `${t("transfers:badges.paid").toUpperCase()}`,
    },
    CANCELADO: {
      class: "canceled",
      name: "CANCELADO",
      displayName: `${t("transfers:badges.canceled").toUpperCase()}`,
    },
    RECUSADO: {
      class: "denied",
      name: "RECUSADO",
      displayName: `${t("transfers:badges.denied").toUpperCase()}`,
    },
  };

  const statusOptions = {
    "EM ANÁLISE": [
      statusBuilder.SOLICITADO.displayName,
      statusBuilder.RECUSADO.displayName,
      statusBuilder.APROVADO.displayName,
    ],
    APROVADO: [
      statusBuilder.PAGO.displayName,
      statusBuilder.CANCELADO.displayName,
      statusBuilder.RECUSADO.displayName,
      statusBuilder.SOLICITADO.displayName,
    ],
  };

  const verifyTransferEligibility = async (
    end_date,
    event_status,
    organization_id
  ) => {
    const today = new Date();
    const eventEndDate = new Date(end_date);
    const isEventFinished =
      event_status !== "ativo" && event_status !== "adiado";

    if (user.is_admin) {
      return;
    }

    if (isEventFinished) {
      setIsButtonDisabled(true);
      return;
    }

    if (today >= eventEndDate) {
      setIsButtonDisabled(true);
      return;
    }

    const {
      data: {
        organization: { producer },
      },
    } = await api.get(`/organization/show/${organization_id}`);

    if (producer.user_id !== user.id) {
      setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    New_api.get("/transfer_transactions_statuses").then(({ data }) => {
      const status = data.map(({ name, id }) => {
        return {
          value: { id, name },
          label: statusBuilder[name.toUpperCase()]?.displayName,
          id,
        };
      });

      setOriginalStatusList(status);
      setStatusList(status);
    });

    if (event_id) {
      New_api.get(`transfers/organization-info/${event_id}`)
        .then(({ data }) => setDefaultEventOrgInfo(data))
        .catch((error) => console.error(error));

      organizationsInfoFetch();
      setEventContext(true);
      setEventId(event_id);
      carregaTransferencias(event_id, "initial", 1, perPage);

      New_api.get(`/events/${event_id}`, {
        params: {
          fields: ["name", "end_date", "event_status", "organization_id"],
        },
      })
        .then(
          async ({
            data: { name, end_date, event_status, organization_id },
          }) => {
            setEventName(name);
            if (activateTransferLock) {
              await verifyTransferEligibility(
                end_date,
                event_status,
                organization_id
              );
            }
          }
        )
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });

      setIsLoading(false);
    } else {
      setEventContext(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [event_id, t]);

  useEffect(() => {
    // eslint-disable-next-line
    if (event_id) {
      carregaTransferencias(event_id, "initial", 1, perPage);
    } else {
      carregaTransferencias(null);
    }

    setReload(false);
    setShowDetails(false);
    // eslint-disable-next-line
  }, [reload]);

  const organizationsInfoFetch = () => {
    New_api.get(`transfers/organization-info/${event_id}`)
      .then(({ data }) => setDefaultEventOrgInfo(data))
      .catch((error) => console.error(error));
  };

  const carregaTransferencias = (
    id = null,
    typePage = "initial",
    numberPage = 1,
    perPage = initialPerPage
  ) => {
    setIsLoading(true);
    let changeNumberPage = numberPage;
    let pageConsult = {
      page: numberPage,
      per_page: perPage,
      by_search: filteredByRecipientOrEvent,
      by_status: filteredByStatus,
      by_destiny: filteredByDestiny,
    };

    switch (typePage) {
      case "Initial":
        pageConsult = {
          page: 1,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
        break;

      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            per_page: perPage,
            by_search: filteredByRecipientOrEvent,
            by_status: filteredByStatus,
            by_destiny: filteredByDestiny,
          };
        } else {
          changeNumberPage++;
          pageConsult = {
            page: changeNumberPage,
            per_page: perPage,
            by_search: filteredByRecipientOrEvent,
            by_status: filteredByStatus,
            by_destiny: filteredByDestiny,
          };
        }
        break;
      case "current":
        pageConsult = {
          page: numberPage,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
        break;

      case "left":
        changeNumberPage--;
        pageConsult = {
          page: changeNumberPage,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            per_page: perPage,
            by_search: filteredByRecipientOrEvent,
            by_status: filteredByStatus,
            by_destiny: filteredByDestiny,
          };
        }
        break;

      case "lastPage":
        pageConsult = {
          page: numberPage,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
        break;
      default:
        pageConsult = {
          page: 1,
          per_page: perPage,
          by_search: filteredByRecipientOrEvent,
          by_status: filteredByStatus,
          by_destiny: filteredByDestiny,
        };
    }

    if ((id || eventId) && user.is_admin) {
      pageConsult["by_event"] = id || eventId;
    }

    const route = user.is_admin
      ? "transfers"
      : `/transfers?by_event=${eventId || id}`;

    const listPromise = New_api.get(route, { params: pageConsult }).then(
      (res) => {
        setTransferencias(res.data.data);
        setAtualizaPaginaAtual(res.data.meta.current_page);
        setAtualizaUltimaPagina(res.data.meta.last_page);
        setAtualizaQuantPesquisa(res.data.meta.total);
      }
    );

    Promise.all([listPromise, fetchSummary()])
      .then(() => {
        if (document.getElementById("loading")) {
          document.getElementById("loading").classList.add("esconde");
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function fetchSummary() {
    let summaryPromise = Promise.resolve();

    if (!!event_id) {
      summaryPromise = New_api.get(`transfers/summary/${event_id}`).then(
        (res) => {
          setSummary((prevState) => ({ ...prevState, ...res.data.summary }));
          setCosts(res.data.deductions.headerData.costsTotal);
        }
      );
    }

    return summaryPromise;
  }

  function openModalDetailsTransfer(item) {
    const { status } = item;
    statusOptionsListBuilder(status.toUpperCase());
    setTransactionStatus({
      label: statusBuilder[status.toUpperCase()].displayName,
      value: originalStatusList.find(
        (item) => item.value.name.toUpperCase() === status.toUpperCase()
      ),
    });

    if (!!user.is_admin) {
      setDefaultEventOrgInfo((prevState) => ({
        ...prevState,
        ...item.event,
      }));
    }

    setSelectedTransfer(item);
    setShowDetails(true);
  }

  const statusOptionsListBuilder = (status) => {
    const statusItems = [
      statusBuilder["APROVADO"].name,
      statusBuilder["EM ANÁLISE"].name,
    ].includes(status)
      ? statusOptions[status]
      : [statusBuilder.SOLICITADO.displayName];

    if (status.toUpperCase() !== statusBuilder.SOLICITADO.name) {
      setStatusList(
        originalStatusList.filter((status) =>
          statusItems.includes(status.label)
        )
      );
    } else {
      setStatusList(
        originalStatusList.filter(
          (status) => !statusItems.includes(status.label)
        )
      );
    }
  };

  const handleUpdatePerPage = (event) => {
    const newPerPage = event.target.value;

    setPerPage(newPerPage);
    carregaTransferencias(eventId, "initial", 1, perPage);
  };

  const handleApproval = (transfer, error, transactionInfos) => {
    if (error) {
      setTransactionStatus(
        originalStatusList.find(({ name }) => name === "AGUARDANDO PAGAMENTO")
      );
      Swal.fire({
        title: t("transfers:swal.approve.error"),
        icon: "warning",
        text: error.response.data.message,
        confirmButtonText: "Ok",
      });
    } else {
      carregaTransferencias();
      setShowDetails(false);
      Swal.fire({
        title: t("transfers:swal.approve.success"),
        icon: "success",
        confirmButtonText: "Ok",
      });

      const index = transferencias.findIndex((item) => item.id === transfer.id);

      transferencias[index] = transfer;
      setTransferencias([...transferencias]);
      setIsLoading(false);
    }
  };

  const handleRefuse = (transfer, error) => {
    if (error) {
      setTransactionStatus(
        originalStatusList.find(({ name }) => name === "EM ANÁLISE")
      );
      Swal.fire({
        title: t("transfers:swal.refuse.error"),
        icon: "warning",
        text: error.response.data.message,
        confirmButtonText: "Ok",
      });
    } else {
      carregaTransferencias();
      setShowDetails(false);
      Swal.fire({
        title: t("transfers:swal.refuse.success"),
        icon: "success",
        confirmButtonText: "Ok",
      });

      const index = transferencias.findIndex((item) => item.id === transfer.id);

      transferencias[index] = transfer;
      setTransferencias([...transferencias]);
      setIsLoading(false);
    }
  };

  const handleCreation = (transfer, error) => {
    if (error) {
      const title = error.message.includes("500")
        ? t("transfers:creationError.internalTitle")
        : t("transfers:creationError.validationTitle");
      const text = error.message.includes("500")
        ? t("transfers:creationError.internalText")
        : t("transfers:creationError.validationText");

      Swal.fire({
        title,
        icon: "error",
        text,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    } else {
      const text = `${t("transfers:swal.create.success.subtitle", {
        transferKey: transfer?.pix_key
          ? t("transfers:swal.create.success.pixKey")
          : t("transfers:swal.create.success.bankAccount"),
        transferValue: formatCurrency(transfer?.amount, {
          currency,
          isCents: true,
        }),
      })}`;

      Swal.fire({
        title: transfer?.justification_document_link
          ? t("transfers:swal.create.success.title")
          : t("transfers:swal.create.success.titleApproved"),
        icon: "success",
        text,
        confirmButtonText: "Ok",
      });
      organizationsInfoFetch();
      fetchSummary()
        .then(() => {})
        .catch(() => window.reload());

      setTransferencias([transfer, ...transferencias]);
    }
  };

  const handleTransferModalClose = () => {
    setTransactionStatus(null);
    setStatusList(originalStatusList);
    setShowDetails((prevState) => !prevState);
    if (!!user.is_admin && !event_id) {
      setDefaultEventOrgInfo(null);
    }
  };

  const handleCreationStatusLog = (transferInfos) => {
    New_api.post("/transfer_transactions_logs", transferInfos)
      .catch((error) => {
        handleTransferModalClose();
        console.error(error);
      })
      .finally(() => setReload(true));
  };

  const handleTransactionStatus = (status, transferInfos) => {
    setTransactionStatus(status);

    const paid = t("transfers:badges.paid").toUpperCase();
    const denied = t("transfers:badges.denied").toUpperCase();

    if (![paid, denied].includes(status.label)) {
      handleCreationStatusLog(transferInfos);
      setTransferencias((prevState) =>
        prevState.map((transfer) => ({
          ...transfer,
          status:
            transferInfos.transfer_transaction_id === transfer.id
              ? status.label
              : transfer.status,
        }))
      );
    }
  };

  const exportInXlxs = async () => {
    const params = {
      by_search: filteredByRecipientOrEvent,
      by_status: filteredByStatus,
      by_destiny: filteredByDestiny,
    };
    const response = await New_api.get(
      `/transfers/xlsx${eventId ? `?event_id=${eventId}` : ""}`,
      {
        responseType: "blob",
        params,
      }
    );

    const href = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "transferências.xlsx");
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(href);
    document.body.removeChild(link);
  };

  const transferDestinySelection = Object.keys(transferDestiny).map((key) => ({
    value: key,
    label: transferDestiny[key],
  }));

  const filterStatus = (e) => {
    const status = e.map((item) => item.value.name);
    setFilteredByStatus(status);
    setReload(true);
  };

  const filterDestiny = (e) => {
    if (e !== null) {
      setFilteredByDestiny(e.value);
    } else {
      setFilteredByDestiny("");
    }
    setReload(true);
  };

  const debouncedFilterRecipientOrEvent = debounce(async (value) => {
    setFilteredByRecipientOrEvent(value);
    setReload(true);
  }, 800);

  const filterRecipientOrEvent = (e) => {
    debouncedFilterRecipientOrEvent(e.target.value.trim());
  };

  return (
    <Fragment>
      <ToastContainer
        ref={(reff) => {
          container = reff;
        }}
        className="toast-top-right"
      />

      <NewPageHeader
        title={t("transfers:header.title")}
        subtitle={t("transfers:header.subtitle")}
        className="bg-white"
        children={
          <div className="expenses-button">
            <ButtonContained
              tooltipText={t("transfers:tooltip.text")}
              disabled={isButtonDisabled}
              isTransfersPage={true}
              className={
                isButtonDisabled
                  ? "disabled-contained-2"
                  : "new-primary-contained primary-contained"
              }
              onClick={() => setShowCreate(true)}
              content={
                <Fragment>
                  {user.is_admin
                    ? t("transfers:header.buttonText1")
                    : t("transfers:header.buttonText2")}
                </Fragment>
              }
            />
          </div>
        }
      />

      <div id="transfer-transactions" className="container-fluid">
        {!!event_id && (
          <>
            <div id="card" className="col-12 mt-4">
              <div className="row">
                {isButtonDisabled && (
                  <div className="text-left">
                    <Alert className="warning-box m-4">
                      <Alert.Heading className="mr-3">
                        <Warning width={24} height={24} />
                      </Alert.Heading>
                      <div>
                        {t("transfers:warningBox.textPart1")}{" "}
                        <Alert.Link
                          href="https://wa.me/5511977401869"
                          className="warning-box-link"
                        >
                          {t("transfers:warningBox.textPart2")}
                        </Alert.Link>{" "}
                        {t("transfers:warningBox.textPart3")}
                      </div>
                    </Alert>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div id="transfer-transactions">
          {!!event_id && (
            <TransferCards
              summary={summary}
              isLoading={isLoading}
              costs={costs}
              isEventContext={isEventContext}
            />
          )}

          <div className={`col-md-12 ${eventId ? "" : "mt-4"}`}>
            <div className="row mt-2">
              <div className="col-12">
                <div className="d-flex flex-wrap align-items-center">
                  <div className="col-md-4 col-12 mb-2 px-0">
                    <NewInput
                      icon={<Search width={20} height={20} />}
                      className="icon-new-input"
                      type="text"
                      onChange={(e) => filterRecipientOrEvent(e)}
                      placeholder={`${t(
                        "transfers:inputs.searchPlaceholder"
                      )} ${
                        !event_id
                          ? t("transfers:inputs.searchPlaceholder2")
                          : ""
                      }`}
                    />
                  </div>

                  <div className="col-md-5 col-12 d-flex flex-wrap mb-2 px-0">
                    <div className="col-md-6 col-12 pl-md-2 px-0 mb-2 mb-md-0">
                      <NewSelect
                        className="w-100 font-weight-bold"
                        classNamePrefix="select"
                        placeholder={t("transfers:inputs.selectPlaceholder")}
                        options={originalStatusList}
                        onChange={filterStatus}
                        isMulti
                      />
                    </div>

                    <div className="col-md-6 col-12 px-md-2 px-0">
                      <NewSelect
                        className="w-100 font-weight-bold"
                        classNamePrefix="select"
                        placeholder={t("transfers:inputs.selectPlaceholder2")}
                        isClearable={true}
                        options={transferDestinySelection}
                        onChange={filterDestiny}
                      />
                    </div>
                  </div>

                  <div className="col-md-3 col-12 mb-2 pl-md-3 px-0 d-flex justify-content-md-end justify-content-start">
                    <ButtonContained
                      className="secondary-outlined select-transfers"
                      onClick={() => exportInXlxs()}
                      content={
                        <Fragment>
                          <Download2 stroke="#0050C3" />
                          {t("transfers:inputs.exportPlaceholder")}
                        </Fragment>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-1">
              <div className="table-responsive">
                <table
                  id="table-totalsales"
                  className="table table-sm card-table"
                >
                  <thead className="thead-light">
                    <tr>
                      {!eventId && (
                        <Fragment>
                          <th scope="col">
                            {t("transfers:table.eventColumn")}
                          </th>
                        </Fragment>
                      )}
                      {!!user.is_admin && !isWhitelabel && (
                        <th scope="col" className="text-left">
                          {t("transfers:table.orgColumn")}
                        </th>
                      )}
                      <th scope="col" className="text-left">
                        {t("transfers:table.userColumn")}
                      </th>
                      <th scope="col" className="text-center">
                        {t("transfers:table.orderColumn")}
                      </th>
                      <th scope="col" className="text-center">
                        {t("transfers:table.dateColumn")}
                      </th>
                      <th scope="col" className="text-center">
                        {t("transfers:table.valueColumn")}
                      </th>
                      <th scope="col" className="text-center">
                        {t("transfers:table.destinyColumn")}
                      </th>
                      <th scope="col" className="text-center">
                        {t("transfers:table.statusColumn")}
                      </th>
                      <th scope="col" className="text-center">
                        {t("transfers:table.detailsColumn")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <>
                        <TableSkeleton
                          showEventColumn={!!event_id ? false : true}
                          showOrgColumn={
                            !!user.is_admin && !isWhitelabel ? true : false
                          }
                          rows={5}
                        />
                      </>
                    ) : (
                      transferencias.map((transfer) => (
                        <tr id="trow-transfers" key={transfer.id}>
                          {!eventId && (
                            <Fragment>
                              <td className="details-code-user">
                                {transfer.event ? transfer.event.name : ""}
                              </td>
                            </Fragment>
                          )}
                          {!!user.is_admin && !isWhitelabel && (
                            <td className="details-code-user">
                              {transfer.event.organization?.name || 'N/A'}
                            </td>
                          )}
                          <td className="text-left">{transfer.name}</td>
                          <td className="text-center">
                            {`${transfer.requester?.first_name} ${transfer.requester?.last_name}`}{" "}
                            <br />
                            {transfer.requester?.email}
                          </td>
                          <td className="text-center">
                            {dateFormatter(transfer.created_at, {
                              shouldShowTime: true,
                              locale: language,
                            })}
                          </td>
                          <td className="text-center">
                            {formatCurrency(transfer.amount, {
                              currency,
                              isCents: true,
                            })}
                          </td>
                          <td className="text-center">
                            {destinyKeys[transfer.destiny] ||
                              t("transfers:table.notInformed")}
                          </td>
                          <td className="text-center">
                            <span
                              className={
                                "status-badge " +
                                statusBuilder[transfer.status.toUpperCase()]
                                  ?.class
                              }
                            >
                              {t(
                                `transfers:badges.${
                                  statusBuilder[transfer.status.toUpperCase()]
                                    ?.class
                                }`
                              ).toUpperCase()}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn-table"
                                onClick={() =>
                                  openModalDetailsTransfer(transfer)
                                }
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              {transferencias.length > 0 && !isLoading && (
                <div className="col-12">
                  <div className="row d-flex justify-content-between datatable-footer">
                    <div className="col-md-6 d-flex align-items-center">
                      <span className="mr-2 ">
                        {t("transfers:pagination.resultsByPage")}
                      </span>
                      <div>
                        <select
                          name="SelecaoPorPagina"
                          onChange={handleUpdatePerPage}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                      {perPage * atualizaPaginaAtual >=
                      atualizaQuantPesquisa ? (
                        <span>
                          {`${t(
                            "transfers:pagination.exhibit"
                          )} ${atualizaQuantPesquisa} ${t(
                            "transfers:pagination.of"
                          )} ${atualizaQuantPesquisa}`}
                        </span>
                      ) : (
                        <span>
                          {`${t("transfers:pagination.exhibit")} ${
                            perPage * atualizaPaginaAtual
                          } ${t(
                            "transfers:pagination.of"
                          )} ${atualizaQuantPesquisa}`}
                        </span>
                      )}

                      <button
                        type="button"
                        onClick={() => {
                          carregaTransferencias(eventId, "initial", 1, perPage);
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          carregaTransferencias(
                            eventId,
                            "left",
                            atualizaPaginaAtual,
                            perPage
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                      <span>
                        {`${t(
                          "transfers:pagination.page"
                        )} ${atualizaPaginaAtual} ${t(
                          "transfers:pagination.of"
                        )} ${atualizaUltimaPagina}`}
                      </span>

                      <button
                        type="button"
                        onClick={() => {
                          carregaTransferencias(
                            eventId,
                            "right",
                            atualizaPaginaAtual,
                            perPage
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>

                      <button
                        type="button"
                        onClick={() => {
                          carregaTransferencias(
                            eventId,
                            "lastPage",
                            atualizaUltimaPagina,
                            perPage
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {!isLoading && (
          <CreateTransferModal
            show={showCreate}
            currency={currency}
            title={
              user.is_admin
                ? t("transfers:header.buttonText1")
                : t("transfers:header.buttonText2")
            }
            statusList={statusList}
            maxValue={
              user.is_admin ? Number.MAX_SAFE_INTEGER : summary.available
            }
            eventId={Number(event_id)}
            eventName={user.is_admin ? null : eventName}
            onError={(error) => handleCreation(null, error)}
            onSuccess={(transfer) => handleCreation(transfer)}
            onClose={() => setShowCreate(false)}
            defaultEventOrgInfo={defaultEventOrgInfo}
            formatCurrency={formatCurrency}
          />
        )}

        <TransferDetailsModal
          show={showDetails}
          transactionStatus={transactionStatus}
          statusList={statusList}
          currency={currency}
          currencySymbol={symbol}
          transfer={selectedTransfer}
          setSelectedTransfer={setSelectedTransfer}
          onApprove={handleApproval}
          onRefuse={handleRefuse}
          handleTransactionStatus={handleTransactionStatus}
          onClose={handleTransferModalClose}
          statusOptionsListBuilder={statusOptionsListBuilder}
          defaultEventOrgInfo={defaultEventOrgInfo}
        />
      </div>
    </Fragment>
  );
}
