import PropTypes from "prop-types";
import "./styles.css";
import React, { useState } from "react";
import ImageInstructionsModal from 'components/imageInstructionsModal';

GroupHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    hr: PropTypes.bool,
    tclass: PropTypes.bool,
    dclass: PropTypes.bool,
    clickableText: PropTypes.string,
    isWhitelabel: PropTypes.bool,
};


export function GroupHeader({ title, description, hr, tclass, dclass, clickableText, isWhitelabel }) {
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(prevShowModal => !prevShowModal);
    return (
        <div className="d-flex flex-column">
            <p className={tclass}>{title}</p>
            <div className="d-flex flex-column">
                <p className={dclass}>{description}
                    {clickableText && (
                        <span className="clickable-text" onClick={toggleModal}>
                            {clickableText}
                        </span>
                    )}
                </p>
            </div>
            {hr && <hr className="hr"></hr>}
            {!isWhitelabel &&
                <ImageInstructionsModal show={showModal} handleClose={toggleModal} />
            }
        </div>
    )
}