import React, { useState, useRef, useEffect, useCallback } from "react";
import "./styles.css";
import PropTypes from "prop-types";

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

function Tooltip({ text, content }) {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("top");
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);

  const checkPosition = useCallback(() => {
    if (!tooltipRef.current || !triggerRef.current) return;

    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const triggerRect = triggerRef.current.getBoundingClientRect();
    const spaceTop = triggerRect.top;
    const spaceBottom = window.innerHeight - triggerRect.bottom;
    const spaceRight = window.innerWidth - triggerRect.right;

    if (spaceTop > tooltipRect.height + 10) {
      setPosition("top");
    } else if (spaceBottom > tooltipRect.height + 10) {
      setPosition("bottom");
    } else if (spaceRight > tooltipRect.width + 10) {
      setPosition("right");
    } else {
      setPosition("left");
    }
  }, []);

  useEffect(() => {
    if (visible) {
      checkPosition();
      window.addEventListener("resize", checkPosition);
    }

    return () => window.removeEventListener("resize", checkPosition);
  }, [visible, checkPosition]);

  return (
    <div className="tooltip-container">
      <div
        ref={triggerRef}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className="tooltip-trigger"
      >
        {content}
      </div>

      {visible && (
        <div ref={tooltipRef} className={`tooltip tooltip-${position}`}>
          {text}
        </div>
      )}
    </div>
  );
}

export default Tooltip;
