import React, { useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumb from "../breadcrumb";
import Api from "../../services/api";
import ModalQrcodeProducer from "pages/common-area/events/modalQrcodeProducer";
import Swal from "sweetalert2";
import EventStatus from "components/eventStatusBadge";
import { useTranslation } from "react-i18next";
import New_api from "services/new-api";
import { AccountDetails } from "components/accountDetails";
import ButtonContained from "components/buttonContained";
import { ReactComponent as WhatsappLogo } from "../../assets/icons/ph_whatsapp-logo.svg";
import Tooltip from "components/newTooltipCustom";

function Headerbar({ path, activeSide }) {
  const { t: tb } = useTranslation("components", {
    keyPrefix: "breadcrumb",
  });
  const [modalPublicIsOpen, setModalPublicIsOpen] = useState(false);

  const LinkWhatsapp = process.env.REACT_APP_WHATSAPP;

  const urlParams = new URLSearchParams(window.location.search);
  let eventId = urlParams.get("event_id");
  if (
    !eventId &&
    (path.includes("financeiro-dashboard/") ||
      path.includes("financeiro-transferencias/"))
  ) {
    const patchs = path.split("/");
    eventId = patchs[patchs.length - 1];
  }
  const userId = urlParams.get("user_id");
  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("user") || "{}")
  );

  const [eventName, setEventName] = React.useState();
  const [userName, setUserName] = React.useState();
  const [location, setLocation] = React.useState(path);
  const [aux, setAux] = React.useState(location);
  const [eventStatus, setEventStatus] = useState();

  if (aux !== path) {
    setLocation(path);
    setAux(path);
  }
  const [breadItens, setBreadItens] = React.useState([]);

  const userlog = React.useCallback(
    async (cronos = null) => {
      if (localStorage.getItem("user")) {
        setUser(JSON.parse(localStorage.getItem("user")));
        if (!user.is_admin && user.doc_enviados === 0) {
          clearInterval(cronos);
        }
        if (user.is_admin) {
          clearInterval(cronos);
        }
      }
    },
    [user.is_admin, user.doc_enviados]
  );

  React.useEffect(() => {
    if (eventId) {
      New_api.get(`/events/${eventId}`, {
        params: { fields: ["name", "event_status"] },
      })
        .then(({ data: { name, event_status } }) => {
          setEventName(name);
          setEventStatus(event_status);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }

    if (userId) {
      Api.get(`getName/${userId}`)
        .then((res) => {
          setUserName(res.data);
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
  }, [eventId, userId]);

  React.useEffect(() => {
    let itens = [{ to: "", label: "", description: "" }];

    userlog();

    if (location.includes("dashboard")) {
      itens = [{ to: "/dashboard", label: tb("dashboard") }];
    }
    if (location.includes("evento")) {
      itens = [{ to: "/eventos", label: tb("events") }];
    }
    if (location.includes("/tickets/participantes/evento")) {
      itens = [
        { to: "/eventos", label: tb("events") },
        {
          to: `/tickets/dashboard?event_id=${eventId}`,
          label: `${eventName}`,
        },
      ];
    }

    if (location.includes("bar")) {
      itens = [
        { to: "/eventos", label: tb("events") },
        {
          to: `/bar/dashboard?event_id=${eventId}`,
          label: `${eventName}`,
        },
      ];

      if (location.includes("menu")) {
        itens = [
          { to: "/eventos", label: tb("menu") },
          {
            to: `/bar/dashboard?event_id=${eventId}`,
            label: `${eventName}`,
          },
        ];
      }
      if (location.includes("stock")) {
        itens = [
          { to: "/eventos", label: tb("events") },
          {
            to: `/bar/dashboard?event_id=${eventId}`,
            label: `${eventName}`,
          },
          {
            to: `/bar/event-stock?event_id=${eventId}`,
            label: tb("stock"),
          },
        ];
      }
      if (location.includes("sector")) {
        itens = [
          { to: "/eventos", label: tb("events") },
          {
            to: `/bar/dashboard?event_id=${eventId}`,
            label: `${eventName}`,
          },
          { to: `/bar/sector?event_id=${eventId}`, label: tb("sectors") },
        ];
      }
    }

    if (location.includes("vendas")) {
      itens = [
        { to: "/eventos", label: tb("events") },
        {
          to: `/tickets/dashboard?event_id=${eventId}`,
          label: `${eventName}`,
        },
      ];
    }

    if (location.includes("detalhes")) {
      itens = [
        { to: "/eventos", label: tb("events") },
        {
          to: `/tickets/dashboard?event_id=${eventId}`,
          label: `${eventName}`,
        },
      ];
    }

    if (location.includes("tickets")) {
      itens = [
        { to: "/eventos", label: tb("events") },
        {
          to: `/tickets/dashboard?event_id=${eventId}`,
          label: `${eventName}`,
        },
      ];
    }

    if (location.includes("checkin")) {
      itens = [
        { to: "/eventos", label: tb("events") },
        {
          to: `/tickets/dashboard?event_id=${eventId}`,
          label: `${eventName}`,
        },
      ];
    }

    if (location.includes("users")) {
      itens = [{ to: "/users", label: tb("users") }];
    }

    if (location.includes("user-edit")) {
      itens = [
        { to: "/users", label: tb("users"), description: `> ${userName}` },
      ];
    }

    if (location.includes("user-data")) {
      itens = [{ to: "/user-data", label: tb("profile") }];
    }

    if (location.includes("organizacoes")) {
      itens = [{ to: "/organizacoes", label: tb("organizations") }];
    }

    if (location.includes("financeiro-dashboard")) {
      itens = [{ to: "/financeiro-dashboard", label: tb("finacialDashboard") }];
    }

    if (location.includes("financeiro-dashboard/")) {
      itens = [
        { to: "/eventos", label: tb("events") },
        {
          to: `/tickets/dashboard?event_id=${eventId}`,
          label: `${eventName}`,
        },
      ];
    }

    if (location.includes("financeiro-transferencias")) {
      itens = [
        {
          to: "/financeiro-transferencias",
          label: tb("finacialTransfers"),
        },
      ];
    }

    if (location.includes("tickets/financeiro-transferencias")) {
      itens = [
        { to: "/eventos", label: tb("events") },
        {
          to: `/tickets/dashboard?event_id=${eventId}`,
          label: `${eventName}`,
        },
      ];
    }

    if (location.includes("financeiro-planos")) {
      itens = [
        {
          to: "/financeiro-planos",
          label: tb("finacialPlans"),
        },
      ];
    }

    if (location.includes("equipment")) {
      if (location.includes("equipment-type")) {
        itens = [{ to: "/equipment-types", label: tb("equipmentType") }];
      } else {
        itens = [{ to: "/equipments", label: tb("equipments") }];
      }
    }

    if (location.includes("products")) {
      itens = [{ to: "/products", label: tb("products") }];
    }

    if (location.includes("establishments")) {
      itens = [{ to: "/establishments", label: tb("establishments") }];
    }

    if (location.includes("register-establishment")) {
      itens = [{ to: "/register-establishment", label: tb("establishment") }];
    }

    if (location.includes("organizacao")) {
      itens = [{ to: "/organizacao", label: tb("organization") }];
    }

    if (location.includes("register-user")) {
      itens = [{ to: "/users", label: tb("users") }];
    }

    if (location.includes("faq")) {
      itens = [{ to: "/faqs", label: tb("faqs") }];
    }

    if (location.includes("configurations")) {
      itens = [{ to: "/configurations", label: tb("configurations") }];
    }

    if (location.includes("events-categories")) {
      itens = [{ to: "/events-categories", label: tb("eventCategories") }];
    }

    if (location.includes("nomenclatures")) {
      itens = [{ to: "/nomenclatures", label: tb("nomenclatures") }];
    }

    setBreadItens(itens);

    const cronologic = setInterval(() => {
      userlog(cronologic);
      // eslint-disable-next-line no-magic-numbers
    }, 10000);
    // eslint-disable-next-line
  }, [location, eventName, userName, eventId, userId, userlog]);

  return (
    user && (
      <div id="nave">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <div id="main-header" className="col-12">
              <button
                type="button"
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={activeSide}
                className="toogle-side-a btn d-inline-block d-lg-none"
                data-toggle="collapse"
                data-target="#headerbarSupportedContent"
                aria-controls="headerbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <FontAwesomeIcon size="lg" icon={faBars} />
              </button>
              <div id="header-breadcrumb">
                <Breadcrumb className="breadcrumb">
                  {breadItens.map(({ to, label, description }, index) => (
                    <div
                      key={`div-${description}`}
                      className="row align-items-center"
                    >
                      <div className="col-auto breadcrumb-mobile">
                        <NavLink to={to}>{label}</NavLink>
                        {description && <span>{description}</span>}
                      </div>
                      {index === breadItens.length - 1 &&
                        (location.includes("/tickets") ||
                          location.includes("bar")) && (
                          <div>
                            <EventStatus status={eventStatus} />
                          </div>
                        )}
                    </div>
                  ))}
                </Breadcrumb>
              </div>
              <div
                className="ml-auto d-flex flex-row justify-content-between account-header-div"
                id="headerbarSupportedContent"
              >
                <Tooltip
                  text={tb("talkWithSupport")}
                  content={
                    <ButtonContained
                      id="call-whatsapp-button"
                      content={<WhatsappLogo fill="#171719" />}
                      onClick={() => {
                        window.open(LinkWhatsapp);
                        document.activeElement.blur();
                      }}
                      className="primary-icon"
                    />
                  }
                />

                <div id="headerbar-usercontent" className="ml-auto">
                  <Tooltip
                    text="Sua conta"
                    content={
                      <AccountDetails
                        handleOpenQrCode={() => setModalPublicIsOpen(true)}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
        <ModalQrcodeProducer
          toggleFunction={{ modalPublicIsOpen, setModalPublicIsOpen }}
          user={user}
        />
      </div>
    )
  );
}

export default Headerbar;
